import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col" }
const _hoisted_2 = { class: "flex items-center justify-between mb-8" }
const _hoisted_3 = { class: "text-sm gb-text-text-main leading-5 font-bold" }
const _hoisted_4 = {
  key: 0,
  class: "text-sm font-medium text-center mb-4"
}
const _hoisted_5 = {
  key: 1,
  class: "flex items-center py-2 px-3 gap-3 rounded-[8px] bg-[#FFF0F0] mb-8"
}
const _hoisted_6 = { class: "font-bold text-[20px] leading-6 gb-text-text-tint-red flex-shrink-0" }
const _hoisted_7 = { class: "opacity-30" }
const _hoisted_8 = { class: "gb-text-text-tint-red text-xs font-medium leading-4" }
const _hoisted_9 = { key: 1 }

import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import { GetBlockUIButton, GetBlockUIIcon, GetBlockUILoader } from '@getblockui/core';
import { useI18n } from 'vue-i18n';
import { usePaymentProcessStore } from '@/features/paymentProcess';
import { computed, defineAsyncComponent } from 'vue';
import { useAccessTokenStore } from '@/entities/accessToken';
import { useGetCurrentPlan } from '@/features/currentPlan/useGetCurrentPlan';


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentProcessModa',
  setup(__props) {

const PaymentProcessModalComponents = {
  SelectPaymentMethod: defineAsyncComponent(
    () => import('@/features/paymentProcess/ui/paymentProcessModal/parts/SelectPaymentMethod.vue'),
  ),
  UpgradePlan: defineAsyncComponent(
    () => import('@/features/paymentProcess/ui/paymentProcessModal/parts/UpgradePlan.vue'),
  ),
  TopUp: defineAsyncComponent(() => import('@/features/paymentProcess/ui/paymentProcessModal/parts/TopUp.vue')),
};

const { t } = useI18n();
const paymentProcessStore = usePaymentProcessStore();
const accessTokensStore = useAccessTokenStore();
const { getCurrentPlan } = useGetCurrentPlan();

const isVisibleBackButton = computed(
  () =>
    paymentProcessStore.getPaymentProcessModalHistory[paymentProcessStore.getPaymentProcessModalHistory.length - 2] !==
    '',
);

const getComponent = computed(() => {
  //@ts-ignore
  return PaymentProcessModalComponents[paymentProcessStore.getPaymentProcessModalComponent];
});

const isInfoVisible = computed(() => {
  return (
    !isVisibleBackButton.value &&
    (paymentProcessStore.getPaymentProcessModalInfo === 'statistics' ||
      paymentProcessStore.getPaymentProcessModalInfo === 'team-members' ||
      paymentProcessStore.getPaymentProcessModalInfo === 'top-up-available')
  );
});

const nextStepButtonText = computed(() => {
  if (paymentProcessStore.getPaymentProcessModalComponent === 'TopUp') {
    if (paymentProcessStore.getSelectedPlan?._key === 'pro') {
      return t(`paymentProcess.paymentProcessModal.buttons.get-pro`);
    }
    return t(`paymentProcess.paymentProcessModal.buttons.top-up`);
  }
  if (paymentProcessStore.getPaymentProcessModalComponent === 'SelectPaymentMethod') {
    return t(`paymentProcess.paymentProcessModal.buttons.proceed-checkout`);
  } else return t(`paymentProcess.paymentProcessModal.buttons.upgrade`);
});

const currentSharedTokensAmount = computed(() => {
  const sharedAccessTokens = accessTokensStore.getSharedAccessTokens;
  return sharedAccessTokens.length;
});

const currentPlanMaxAmountOfTokens = computed(() => {
  return getCurrentPlan.value.max_token_amount;
});

const showTokenLimitWarning = computed(() => {
  return (
    paymentProcessStore.getPaymentProcessModalInfo === 'tokens-amount' &&
    currentSharedTokensAmount.value >= currentPlanMaxAmountOfTokens.value
  );
});

const back = () => {
  paymentProcessStore.backInHistoryComponent();
};
const close = () => {
  paymentProcessStore.setPaymentProcessModalInfo(null);
  paymentProcessStore.setPaymentProcessModalDisplay(false);
};

const callApiForBuyPlan = async () => {
  await paymentProcessStore.requestPay();
};

const nextStep = () => {
  if (paymentProcessStore.getPaymentProcessModalComponent === 'SelectPaymentMethod') callApiForBuyPlan();
  else paymentProcessStore.setPaymentProcessModalComponent('SelectPaymentMethod');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalTemplate, {
    "additional-classes": "max-w-[400px] w-[400px]",
    paddings: "p-6",
    rounded: "rounded-24",
    onClose: close
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("header", _hoisted_2, [
          _createVNode(_unref(GetBlockUIButton), {
            class: _normalizeClass({ 'opacity-0': !isVisibleBackButton.value }),
            "icon-button": "",
            "size-type": "small",
            "color-type": "secondary"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(GetBlockUIIcon), {
                "icon-name": "chevron-left",
                onClick: back
              })
            ]),
            _: 1
          }, 8, ["class"]),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)(`paymentProcess.paymentProcessModal.title.${_unref(paymentProcessStore).getPaymentProcessModalComponent}`)), 1),
          _createVNode(_unref(GetBlockUIButton), {
            "icon-button": "",
            "size-type": "small",
            "color-type": "secondary",
            onClick: close
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(GetBlockUIIcon), { "icon-name": "x-close" })
            ]),
            _: 1
          })
        ]),
        (isInfoVisible.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_unref(t)(`paymentProcess.paymentProcessModal.info.${_unref(paymentProcessStore).getPaymentProcessModalInfo}`)), 1))
          : _createCommentVNode("", true),
        (showTokenLimitWarning.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, [
                _createTextVNode(_toDisplayString(currentSharedTokensAmount.value), 1),
                _createElementVNode("span", _hoisted_7, "/" + _toDisplayString(currentPlanMaxAmountOfTokens.value), 1)
              ]),
              _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(t)('shared-nodes.modals.upgrade-plan.tokens-warning')), 1)
            ]))
          : _createCommentVNode("", true),
        (_openBlock(), _createBlock(_resolveDynamicComponent(getComponent.value))),
        _createVNode(_unref(GetBlockUIButton), {
          class: "mt-8 flex justify-center",
          onClick: nextStep
        }, {
          default: _withCtx(() => [
            (_unref(paymentProcessStore).getProcessOfReceivingPaymentLink)
              ? (_openBlock(), _createBlock(_unref(GetBlockUILoader), {
                  key: 0,
                  class: "m-auto"
                }))
              : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(nextStepButtonText.value), 1))
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}
}

})