export  const changeNumberDisplay = (number: number, isToFixed: boolean = true): string => {
  if (!number) return '0';
  if (number < 1000) return `${number}`;
  if (number >= 1000 && number < 1000000) {
    if (number % 1000 !== 0 && isToFixed) return `${(number / 1000).toFixed(1)}k`;
    if (number % 1000 !== 0 && !isToFixed) return `${Math.floor(number / 1000).toFixed(0)}k`
    return `${number / 1000}k`;
  }
  if (number % 1000000 !== 0 && isToFixed) return `${(number / 1000000).toFixed(1)}M`;
  if (number % 1000000 !== 0 && !isToFixed) return `${Math.floor(number / 1000000).toFixed(0)}M`
  return `${number / 1000000}M`;
};

export const formatNumberCompact = (number: number) => {
  return new Intl.NumberFormat('en-EN', { notation: 'compact' }).format(number);
}
