<script lang="ts" setup>
import { GetBlockUIIcon } from '@getblockui/core';
import { PropType, defineProps } from 'vue';
import { DedicatedPlanType, EnterprisePlanCUType, FreePlanProductType, ProPlanCUType, StarterPlanCUType } from '@/shared/products/types';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  type: {
    type: String as PropType<StarterPlanCUType | ProPlanCUType | EnterprisePlanCUType | DedicatedPlanType>,
    default: ''
  }
})

const Options: Readonly<{[key in FreePlanProductType | StarterPlanCUType | ProPlanCUType | EnterprisePlanCUType | DedicatedPlanType]: {text: string, include: boolean}[]}> = {
  free: [
    {
      text: 'supported-chains',
      include: true,
    },
    {
      text: 'dedicated-nodes',
      include: true,
    },
    {
      text: 'support',
      include: false,
    },
    {
      text: 'team-account',
      include: false,
    },
    {
      text: 'custom-rollups',
      include: false,
    },
    {
      text: 'statistics',
      include: false,
    },
    {
      text: 'dedicated-clusters',
      include: false,
    },
  ],
  starter: [
    {
      text: 'supported-chains',
      include: true,
    },
    {
      text: 'dedicated-nodes',
      include: true,
    },
    {
      text: 'support',
      include: true,
    },
    {
      text: 'statistics',
      include: true,
    },
    {
      text: 'team-account',
      include: false,
    },
    {
      text: 'custom-rollups',
      include: false,
    },
    {
      text: 'dedicated-clusters',
      include: false,
    },
  ],
  pro: [
    {
      text: 'supported-chains',
      include: true,
    },
    {
      text: 'dedicated-nodes',
      include: true,
    },
    {
      text: 'support',
      include: true,
    },
    {
      text: 'statistics',
      include: true,
    },
    {
      text: 'team-account',
      include: true,
    },
    {
      text: 'custom-rollups',
      include: false,
    },
    {
      text: 'dedicated-clusters',
      include: false,
    },
  ],
  enterprise_plan: [
    {
      text: 'supported-chains',
      include: true,
    },
    {
      text: 'dedicated-nodes',
      include: true,
    },
    {
      text: 'support',
      include: true,
    },
    {
      text: 'advanced-statistics',
      include: true,
    },
    {
      text: 'team-account',
      include: true,
    },
    {
      text: 'custom-rollups',
      include: true,
    },
    {
      text: 'dedicated-clusters',
      include: false,
    },
  ],
  dedicated: [
    {
      text: 'supported-chains',
      include: true,
    },
    {
      text: 'dedicated-nodes',
      include: true,
    },
    {
      text: 'support',
      include: true,
    },
    {
      text: 'advanced-statistics',
      include: true,
    },
    {
      text: 'team-account',
      include: true,
    },
    {
      text: 'custom-rollups',
      include: true,
    },
    {
      text: 'dedicated-clusters',
      include: true,
    },
  ],
}
const Icon = {
  true: 'check',
  false: 'minus'
}
const IconColor = {
  true: 'gb-bg-text-action',
  false: 'gb-bg-surface-fog'
}
const TextColor = {
  true: 'gb-text-text-main',
  false: 'gb-text-text-tertiary'
}

const { t } = useI18n();
</script>

<template>
  <div class="flex flex-col gap-4">
    <div v-for="option in Options[props.type]" :key="option" class="flex items-center gap-3">
      <div
        class="p-0.5 w-[15px] h-[15px] rounded-full flex items-center justify-center"
        :class="[IconColor[option.include], { 'bg-[#878D99]': type === 'dedicated' }]"
      >
        <GetBlockUIIcon :color-type="type === 'dedicated' ? 'main' : 'color-bg'" size-type="xs" :icon-name="Icon[option.include]" />
      </div>
      <p class="font-medium text-sm " :class="TextColor[option.include]">
        {{ t(`pricing-card.plans.${props.type}.pricing-card-options.${option.text}`) }}
      </p>
    </div>
  </div>
</template>
