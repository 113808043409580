export default {
  paymentProcess: {
    paymentProcessModal: {
      title: {
        SelectPaymentMethod: 'Select payment method',
        UpgradePlan: 'Upgrade your plan',
        TopUp: 'Top Up Your Balance',
      },
      info: {
        statistics: 'Upgrade your plan to get access to statistics',
        'team-members': 'Upgrade your plan to add team members',
        'tokens-amount': 'Please upgrade your plan to increase amount of access tokens.',
        'top-up-available': 'Top up available on any payment plan'
      },
      buttons: {
        'upgrade': 'Upgrade',
        'proceed-checkout': 'Proceed to checkout',
        'top-up': 'Top up',
        'get-pro': 'Get Pro'
      },
    },
    parts: {
      SelectedPaymentMethod: {
        subscription: 'Subscription',
        'one-time': 'One time payment',
      },
      SelectedPlan: {
        'per-month': '/ per month',
        'cu-month': 'CU / month',
        rps: 'RPS',
        'access-tokens': 'Access Tokens',
      },
      TotalPrice: {
        total: 'Total:',
        vat: 'VAT not included. Final price depends on your country of residence.',
      },
      TopUp: {
        or: 'or',
        'cu-amount': 'CU Amount',
        for: 'for',
        cu: 'CU',
        '1m-cu': '/ 1M CU',
      },
    },
  },
};
