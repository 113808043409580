import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { INode } from './types';
import { fetchNodes } from './api';
import { AppNotificationModule } from '@/application'
import { Node } from '@/types/shared/shared';

const useNodesPrivateStore = defineStore('nodesPrivate', () => {
  const nodes = ref<INode[]>([]);

  const setNodes = (payload: INode[]) => {
    nodes.value = payload
  }

  return {
    nodes,
    setNodes
  }
})

export const useNodesStore = defineStore('nodes', () => {
  const privateStore = useNodesPrivateStore();
  const notificationStore = AppNotificationModule.useNotificationStore();

  const getNodes = computed(() => privateStore.nodes)
  const getDedicatedNodes = computed(() => getNodes.value.filter((node: Node) => node.service_levels.find((serviceLevel) => serviceLevel._key === 'dedicated')))
  const getSharedNodes = computed(() => getNodes.value.filter((node: Node) => node.service_levels.find((serviceLevel) => serviceLevel._key === 'shared')))
  const getNodeName = (ticker: string) => {
    const node = privateStore.nodes.find((node) => node.protocol._key === ticker)
    return node ? node.protocol.name : ticker
  }
  const requestNodes = async () => {
    try {
      const response = await fetchNodes()
      privateStore.setNodes(response.data)
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      notificationStore.setNotificationMessage({ ...errorData('general_get-list-of-nodes'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true);
    }
  }

  return {
    getNodes,
    getDedicatedNodes,
    getSharedNodes,
    getNodeName,
    requestNodes
  }
})