import { SharedApiModule } from '@/shared'
import store from '@/store';
import { AxiosResponse } from 'axios';
import { CUPackageIdType, CUPackageType, RequestsPackId } from '@/shared/products/types';
import { ICUPackage, IRequestPackages } from '@/entities/requestPackage/types';

const fetchPacks = () => SharedApiModule.HttpClients.httpClientV3.get('/prices/packs/', {
  headers: {
    Authorization: `Bearer ${store.state.accessToken}`,
  },
}) as Promise<AxiosResponse<{[key in RequestsPackId | CUPackageIdType<CUPackageType>]: IRequestPackages | ICUPackage<CUPackageType>}>>

export { fetchPacks }