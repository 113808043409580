import { PackagesActions, PackagesGetters, PackagesMutations, PackagesState } from '@/types/packages/store';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/store';
import { AppNotificationModule } from '@/application';
import { fetchPlans } from '@/entities/sharedPlans/api';
import { ISharedPlans } from '@/entities/sharedPlans/types';
import { ProPlanProductId, StartPlanProductId, UnlimitedPlanProductId } from '@/shared/products/types';
import { IRequestPackages } from '@/entities/requestPackage/types';
import { fetchPacks } from '@/entities/requestPackage/api';

export type State = PackagesState;
export type Mutations = PackagesMutations;
export type Getters = PackagesGetters;
export type Actions = PackagesActions;

const state: () => State = () => ({
  startPlans: [],
  proPlan: [],
  enterprisePlans: [],
  unlimitedPlans: [],
  packs: [],
});

const getters: GetterTree<State, RootState> & Getters = {};

const mutations: MutationTree<State> & Mutations = {
  setStartPlans(state, payload) {
    state.startPlans = payload
  },
  setProPlans(state, payload) {
    state.proPlan = payload
  },
  setUnlimitedPlans(state, payload) {
    state.unlimitedPlans = payload
  },
  setPacks(state, payload) {
    state.packs = payload
  }
};

const actions: ActionTree<State, RootState> & Actions = {
  async requestPlans({commit}) {
    try {
      let { data } = await fetchPlans();
      data = {
        "plans/pro": {
          "_id": "plans/pro",
          "_key": "pro",
          "name": "Pro",
          "price": 499,
          "rps": 200,
          "cu_amount": 600000000,
          "max_token_amount": 50,
          "visible": true,
          "is_archived": false,
          "periods_with_prices": {
            "12m": {
              "base": 5988,
              "first_payment": {
                "discount_price": 4790,
                "discount_name": "Long period"
              },
              "regular": {
                "discount_price": 4790,
                "discount_name": "Long period"
              }
            },
            "1m": {
              "base": 499,
              "first_payment": {
                "discount_price": 499,
                "discount_name": ""
              },
              "regular": {
                "discount_price": 499,
                "discount_name": ""
              }
            }
          }
        },
        // @ts-ignore
        "plans/start100m200": {
          "_id": "plans/start100m200",
          "_key": "start100m200",
          "name": "Start",
          "price": 339,
          "rps": 200,
          "cu_amount": 0,
          "max_token_amount": 0,
          "visible": true,
          "is_archived": false,
          "periods_with_prices": {
            "12m": {
              "base": 4068,
              "first_payment": {
                "discount_price": 3254,
                "discount_name": "Long period"
              },
              "regular": {
                "discount_price": 3254,
                "discount_name": "Long period"
              }
            },
            "1m": {
              "base": 339,
              "first_payment": {
                "discount_price": 339,
                "discount_name": ""
              },
              "regular": {
                "discount_price": 339,
                "discount_name": ""
              }
            }
          }
        },
        // @ts-ignore
        "plans/start10m200": {
          "_id": "plans/start10m200",
          "_key": "start10m200",
          "name": "Start",
          "price": 49,
          "rps": 200,
          "cu_amount": 0,
          "max_token_amount": 0,
          "visible": true,
          "is_archived": false,
          "periods_with_prices": {
            "12m": {
              "base": 588,
              "first_payment": {
                "discount_price": 470,
                "discount_name": "Long period"
              },
              "regular": {
                "discount_price": 470,
                "discount_name": "Long period"
              }
            },
            "1m": {
              "base": 49,
              "first_payment": {
                "discount_price": 49,
                "discount_name": ""
              },
              "regular": {
                "discount_price": 49,
                "discount_name": ""
              }
            }
          }
        },
        // @ts-ignore
        "plans/start50m200": {
          "_id": "plans/start50m200",
          "_key": "start50m200",
          "name": "Start",
          "price": 199,
          "rps": 200,
          "cu_amount": 0,
          "max_token_amount": 0,
          "visible": true,
          "is_archived": false,
          "periods_with_prices": {
            "12m": {
              "base": 2388,
              "first_payment": {
                "discount_price": 1910,
                "discount_name": "Long period"
              },
              "regular": {
                "discount_price": 1910,
                "discount_name": "Long period"
              }
            },
            "1m": {
              "base": 199,
              "first_payment": {
                "discount_price": 199,
                "discount_name": ""
              },
              "regular": {
                "discount_price": 199,
                "discount_name": ""
              }
            }
          }
        },
        // @ts-ignore
        "plans/start5m200": {
          "_id": "plans/start5m200",
          "_key": "start5m200",
          "name": "Start",
          "price": 29,
          "rps": 200,
          "cu_amount": 0,
          "max_token_amount": 0,
          "visible": true,
          "is_archived": false,
          "periods_with_prices": {
            "12m": {
              "base": 348,
              "first_payment": {
                "discount_price": 278,
                "discount_name": "Long period"
              },
              "regular": {
                "discount_price": 278,
                "discount_name": "Long period"
              }
            },
            "1m": {
              "base": 29,
              "first_payment": {
                "discount_price": 29,
                "discount_name": ""
              },
              "regular": {
                "discount_price": 29,
                "discount_name": ""
              }
            }
          }
        },
        "plans/starter": {
          "_id": "plans/starter",
          "_key": "starter",
          "name": "Starter",
          "price": 49,
          "rps": 25,
          "cu_amount": 50000000,
          "max_token_amount": 10,
          "visible": true,
          "is_archived": false,
          "periods_with_prices": {
            "12m": {
              "base": 588,
              "first_payment": {
                "discount_price": 470,
                "discount_name": "Long period"
              },
              "regular": {
                "discount_price": 470,
                "discount_name": "Long period"
              }
            },
            "1m": {
              "base": 49,
              "first_payment": {
                "discount_price": 49,
                "discount_name": ""
              },
              "regular": {
                "discount_price": 49,
                "discount_name": ""
              }
            }
          }
        },
        // @ts-ignore
        "plans/unlim300": {
          "_id": "plans/unlim300",
          "_key": "unlim300",
          "name": "Unlimited",
          "price": 499,
          "rps": 300,
          "cu_amount": 0,
          "max_token_amount": 0,
          "visible": true,
          "is_archived": false,
          "periods_with_prices": {
            "12m": {
              "base": 5988,
              "first_payment": {
                "discount_price": 4790,
                "discount_name": "Long period"
              },
              "regular": {
                "discount_price": 4790,
                "discount_name": "Long period"
              }
            },
            "1m": {
              "base": 499,
              "first_payment": {
                "discount_price": 499,
                "discount_name": ""
              },
              "regular": {
                "discount_price": 499,
                "discount_name": ""
              }
            }
          }
        }
      };
      const startPlans = Object.values(data).filter((item) => item._id.includes('start'))
      const unlimitedPlans = Object.values(data).filter((item) => item._id.includes('unlim'))
      const proPlans = Object.values(data).filter((item) => item._id.includes('pro'))
      commit('setStartPlans', startPlans.sort((a,b) => a.cu_amount! - b.cu_amount!))
      commit('setUnlimitedPlans', unlimitedPlans)
      commit('setProPlans', proPlans)
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      const notificationStore = AppNotificationModule.useNotificationStore();
      notificationStore.setNotificationMessage(  { ...errorData('general_get-increase-pricing'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true);
    }
  },
  async requestPacks({commit}) {
    try {
      const { data } = await fetchPacks()
      commit('setPacks', Object.values(data) as IRequestPackages[])
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      const notificationStore = AppNotificationModule.useNotificationStore();
      notificationStore.setNotificationMessage(  { ...errorData('general_get-increase-pricing'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true);
    }
  }
};

const packages: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
};

export default packages;
