import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4" }

import { GetBlockUIIcon } from '@getblockui/core';
import { PropType } from 'vue';
import { DedicatedPlanType, EnterprisePlanCUType, FreePlanProductType, ProPlanCUType, StarterPlanCUType } from '@/shared/products/types';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'PricingCardOptions',
  props: {
  type: {
    type: String as PropType<StarterPlanCUType | ProPlanCUType | EnterprisePlanCUType | DedicatedPlanType>,
    default: ''
  }
},
  setup(__props) {

const props = __props

const Options: Readonly<{[key in FreePlanProductType | StarterPlanCUType | ProPlanCUType | EnterprisePlanCUType | DedicatedPlanType]: {text: string, include: boolean}[]}> = {
  free: [
    {
      text: 'supported-chains',
      include: true,
    },
    {
      text: 'dedicated-nodes',
      include: true,
    },
    {
      text: 'support',
      include: false,
    },
    {
      text: 'team-account',
      include: false,
    },
    {
      text: 'custom-rollups',
      include: false,
    },
    {
      text: 'statistics',
      include: false,
    },
    {
      text: 'dedicated-clusters',
      include: false,
    },
  ],
  starter: [
    {
      text: 'supported-chains',
      include: true,
    },
    {
      text: 'dedicated-nodes',
      include: true,
    },
    {
      text: 'support',
      include: true,
    },
    {
      text: 'statistics',
      include: true,
    },
    {
      text: 'team-account',
      include: false,
    },
    {
      text: 'custom-rollups',
      include: false,
    },
    {
      text: 'dedicated-clusters',
      include: false,
    },
  ],
  pro: [
    {
      text: 'supported-chains',
      include: true,
    },
    {
      text: 'dedicated-nodes',
      include: true,
    },
    {
      text: 'support',
      include: true,
    },
    {
      text: 'statistics',
      include: true,
    },
    {
      text: 'team-account',
      include: true,
    },
    {
      text: 'custom-rollups',
      include: false,
    },
    {
      text: 'dedicated-clusters',
      include: false,
    },
  ],
  enterprise_plan: [
    {
      text: 'supported-chains',
      include: true,
    },
    {
      text: 'dedicated-nodes',
      include: true,
    },
    {
      text: 'support',
      include: true,
    },
    {
      text: 'advanced-statistics',
      include: true,
    },
    {
      text: 'team-account',
      include: true,
    },
    {
      text: 'custom-rollups',
      include: true,
    },
    {
      text: 'dedicated-clusters',
      include: false,
    },
  ],
  dedicated: [
    {
      text: 'supported-chains',
      include: true,
    },
    {
      text: 'dedicated-nodes',
      include: true,
    },
    {
      text: 'support',
      include: true,
    },
    {
      text: 'advanced-statistics',
      include: true,
    },
    {
      text: 'team-account',
      include: true,
    },
    {
      text: 'custom-rollups',
      include: true,
    },
    {
      text: 'dedicated-clusters',
      include: true,
    },
  ],
}
const Icon = {
  true: 'check',
  false: 'minus'
}
const IconColor = {
  true: 'gb-bg-text-action',
  false: 'gb-bg-surface-fog'
}
const TextColor = {
  true: 'gb-text-text-main',
  false: 'gb-text-text-tertiary'
}

const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Options[props.type], (option) => {
      return (_openBlock(), _createElementBlock("div", {
        key: option,
        class: "flex items-center gap-3"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["p-0.5 w-[15px] h-[15px] rounded-full flex items-center justify-center", [IconColor[option.include], { 'bg-[#878D99]': __props.type === 'dedicated' }]])
        }, [
          _createVNode(_unref(GetBlockUIIcon), {
            "color-type": __props.type === 'dedicated' ? 'main' : 'color-bg',
            "size-type": "xs",
            "icon-name": Icon[option.include]
          }, null, 8, ["color-type", "icon-name"])
        ], 2),
        _createElementVNode("p", {
          class: _normalizeClass(["font-medium text-sm", TextColor[option.include]])
        }, _toDisplayString(_unref(t)(`pricing-card.plans.${props.type}.pricing-card-options.${option.text}`)), 3)
      ]))
    }), 128))
  ]))
}
}

})