<script lang="ts" setup>
import { computed, defineProps, PropType } from 'vue';
import {
  CUPlansIdType,
  DedicatedPlanType,
  EnterprisePlanCUType,
  ProPlanCUType,
  StarterPlanCUType,
} from '@/shared/products/types';
import { useI18n } from 'vue-i18n';
import { Period12MType, Period1MType } from '@/shared/service/types';

const { t } = useI18n()

const props = defineProps({
  price: {
    type: Number,
    default: 0
  },
  name: {
    type: String,
    default: ''
  },
  planId: {
    type: String as PropType<CUPlansIdType<StarterPlanCUType | ProPlanCUType | EnterprisePlanCUType | DedicatedPlanType>>,
    default: ''
  },
  basePrice: {
    type: Number,
    default: 0
  },
  period: {
    type: String as PropType<Period1MType | Period12MType>,
    default: '1m'
  }
})

const pricePlan = computed(() => props.price)
</script>

<template>
  <div class="flex flex-col justify-between h-[94px]" :class='{ "!h-[114px]" : planId === "plans/dedicated", "!h-[106px]" : planId === "plans/enterprise_plan" }'>
    <div class='flex items-center justify-between'>
      <p class="font-bold text-2xl leading-[30px] gb-text-text-main">{{ props.name }}</p>
      <div
        v-if='planId === "plans/pro"'
        class='text-[12px] font-bold leading-[14px] text-text-tint-blue bg-[#EBF3FF] border border-[#C2DAFF] py-1 px-1.5 rounded-16'
      >
        {{ t('pricing-card.most-popular') }}
      </div>
    </div>
    <p v-if='planId === "plans/pro" || planId === "plans/starter" || planId === "plans/free"' class='flex gap-1 items-baseline'>
      <span class="font-medium text-2xl leading-10 gb-text-text-tertiary">$</span>
      <span class="font-bold text-[40px] leading-[48px] gb-text-text-main">
        {{ period === '1m' ? pricePlan : Math.floor(pricePlan / 12) }}
      </span>
      <span class="font-medium text-sm gb-text-text-tertiary">
        {{ $t('pricing-card.header.per-month') }}
      </span>
    </p>
    <div class='flex flex-col items-start' v-else>
      <p class='text-[14px] text-[#8E8E93] leading-5' :class='{ "!text-[#828999]": planId === "plans/dedicated" }'>
        {{ t('pricing-card.starting-at') }}
      </p>
      <div>
        <span class="font-medium text-2xl leading-10 gb-text-text-tertiary">$</span>
        <span class="font-bold text-[40px] leading-[48px] gb-text-text-main">
        {{ basePrice }}
      </span>
        <span class="font-medium text-sm gb-text-text-tertiary" :class='{ "!text-[#828999]": planId === "plans/dedicated" }'>
        {{ $t('pricing-card.header.per-month') }}
      </span>
      </div>
    </div>
  </div>
</template>
