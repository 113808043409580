import { v4 as uuidv4 } from 'uuid';
import {
  EventTrackerActions,
  EventTrackerGetters,
  EventTrackerMutations,
  EventTrackerState,
} from '@/types/eventTracker/store';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/store';
import { AppNotificationModule } from '@/application';
import { generateNewPublicKey, getPublicKey } from '@/api/notifications';

export type State = EventTrackerState;
export type Mutations = EventTrackerMutations;
export type Getters = EventTrackerGetters;
export type Actions = EventTrackerActions;


const state: () => State = () => ({
  // TODO: move it to notifications/general modules
  publicKey: '',
});
const getters: GetterTree<State, RootState> & Getters = {
};

const mutations: MutationTree<State> & Mutations = {
  setPublicKey(state, key) {
    state.publicKey = key;
  },
};
const actions: ActionTree<State, RootState> & Actions = {
  async getPublicKey({ commit }) {
    try {
      const res = await getPublicKey();
      commit('setPublicKey', res.data);
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      const notificationStore = AppNotificationModule.useNotificationStore();
      notificationStore.setNotificationMessage({ ...errorData('webhooks_get-public-key'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true);
    }
  },
  async generateNewPublicKey({ commit }, recaptcha) {
    try {
      const res = await generateNewPublicKey(recaptcha);
      commit('setPublicKey', res.data);
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      const notificationStore = AppNotificationModule.useNotificationStore();
      notificationStore.setNotificationMessage({ ...errorData('webhooks_generate-public-key'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true);
    }
  },
};

const eventTracker: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
};

export default eventTracker;
