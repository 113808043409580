import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "fixed flex flex-col gap-4 z-[21] top-6 right-6 w-[320px] p-4 gap-4 rounded-20",
  style: {"background":"linear-gradient(271deg, #DB87E9 0.39%, #9343FC 47.15%, #391771 99.59%)"}
}
const _hoisted_2 = { class: "flex flex-col gap-1 max-w-[240px]" }
const _hoisted_3 = { class: "font-bold leading-5 text-text-color-bg" }
const _hoisted_4 = { class: "text-sm leading-5 text-text-color-bg" }
const _hoisted_5 = { class: "flex flex-row justify-between items-center" }
const _hoisted_6 = { class: "flex flex-row gap-1.5" }
const _hoisted_7 = {
  class: "flex items-center justify-center h-8 w-8 rounded-12",
  style: {"background":"rgba(255, 255, 255, 0.16)"}
}
const _hoisted_8 = { class: "font-bold leading-5 text-text-color-bg" }

import { GetBlockUIButton } from '@getblockui/core';
import { computed, nextTick, onMounted, watch } from 'vue';
import { useStore } from '@/store';
import { onboardingData } from '@/components/onboarding/onboardingData';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useGtm } from '@gtm-support/vue-gtm';


export default /*@__PURE__*/_defineComponent({
  __name: 'StepScreen',
  setup(__props) {

const store = useStore();
const { t } = useI18n();
const gtm = useGtm();
const router = useRouter();

const stepNumber = computed(() => store.state.general.onboardingData.stepNumber);
const type = computed(() => store.state.general.onboardingData.type);
const isDataLoading = computed(() => store.state.general.loading);
const countOfSteps = computed(() => onboardingData[type.value] ? onboardingData[type.value].length : 0);

const data = computed(() => {
  const key = `onboarding.${type.value}.${stepNumber.value}`;
  return {
    title: t(`${key}.title`),
    description: t(`${key}.description`),
    button: t(`${key}.button`),
    stepNumber: stepNumber.value + 1,
  };
});

const handleContinueClick = () => {
  if (stepNumber.value + 1 < onboardingData[type.value].length) {
    store.commit('setOnboardingData', { stepNumber: stepNumber.value + 1 });
    return;
  }

  store.commit('setShowOnboarding', {
    stepsModal: false,
  });
};

const handleSkipTutorialClick = () => {
  store.commit('setShowOnboarding', {
    stepsModal: false,
  });
};

watch(() => [store.state.general.onboardingData, isDataLoading.value], async () => {
  if (!isDataLoading.value) {
    // note: this code clear previous step highlighted elements
    if (stepNumber.value >= 1) {
      const { page, elementsToHighlight } = onboardingData[type.value][stepNumber.value - 1];
      elementsToHighlight.forEach((element) => {
        const foundedElement = document.querySelector(`.${element}`);
        if (foundedElement) {
          foundedElement.style.zIndex = 'auto';
          if (type.value === 'base') {
            foundedElement.style.pointerEvents = 'auto';
          }
        }
      });
    }
    const { page, elementsToHighlight } = onboardingData[type.value][stepNumber.value];
    await router.push(page);
    elementsToHighlight.forEach((element) => {
      const foundedElement = document.querySelector(`.${element}`);
      if (foundedElement) {
        foundedElement.style.zIndex = 21;
        if (type.value === 'base') {
          foundedElement.style.pointerEvents = 'none';
        }
      }
    });
  }
}, { immediate: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "modal fixed z-20 top-0 left-0 h-full w-full flex justify-center items-center xs:items-end xs:left-0 xs:right-0" }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(data.value.title), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(data.value.description), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_unref(GetBlockUIButton), {
            "color-type": "white",
            "size-type": "small",
            onClick: handleContinueClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(data.value.button), 1)
            ]),
            _: 1
          }),
          (stepNumber.value !== countOfSteps.value - 1)
            ? (_openBlock(), _createBlock(_unref(GetBlockUIButton), {
                key: 0,
                "color-type": "tiny-white",
                "size-type": "small",
                onClick: handleSkipTutorialClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('onboarding.skip-tutorial')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(data.value.stepNumber), 1)
        ])
      ])
    ])
  ], 64))
}
}

})