export default  {
  'pricing-card': {
    header: {
      'per-month': '/ per month'
    },
    parameters: {
      'cu-month':'CU / month',
      '1m-cu': '/ 1M CU',
      'rps': "RPS",
      'access-token': 'Access tokens',
      'unlimited': 'Unlimited',
      'up-to': 'Up to',
      custom: "Custom",
    },
    plans: {
      free: {
        'button': 'Get Started',
        'pricing-card-options':{
          'supported-chains': 'All supported chains',
          'dedicated-nodes': 'Dedicated nodes',
          'support': '24/7 Support',
          'team-account': 'Team account',
          'dedicated-clusters': 'Dedicated clusters',
          'advanced-statistics': 'Advanced statistics',
          'statistics': 'Statistics',
          'custom-rollups': 'Custom AppChains'
        },
      },
      starter: {
        'button': 'Get Started',
        'pricing-card-options':{
          'supported-chains': 'All supported chains',
          'dedicated-nodes': 'Dedicated nodes',
          'support': '24/5 Support',
          'team-account': 'Team account',
          'dedicated-clusters': 'Dedicated clusters',
          'advanced-statistics': 'Advanced statistics',
          'statistics': 'Statistics',
          'custom-rollups': 'Custom AppChains'
        },
      },
      pro: {
        'button': 'Get Started',
        'pricing-card-options':{
          'supported-chains': 'All supported chains',
          'dedicated-nodes': 'Dedicated nodes',
          'support': '24/7 support',
          'team-account': 'Team account',
          'dedicated-clusters': 'Dedicated clusters',
          'advanced-statistics': 'Advanced statistics',
          'statistics': 'Statistics',
          'custom-rollups': 'Custom AppChains'
        },
      },
      enterprise_plan: {
        header: {
          price: "Lets's talk"
        },
        'button': 'Customize',
        'pricing-card-options':{
          'supported-chains': 'All supported chains',
          'dedicated-nodes': 'Dedicated nodes',
          'support': '24/7 Support',
          'team-account': 'Team account',
          'dedicated-clusters': 'Dedicated clusters',
          'advanced-statistics': 'Advanced statistics',
          'statistics': 'Statistics',
          'custom-rollups': 'Custom AppChains'
        },
      },
      dedicated: {
        'button': 'Start building',
        'pricing-card-options':{
          'supported-chains': 'All supported chains',
          'dedicated-nodes': 'Archive data',
          'support': '24/7 Support',
          'team-account': 'Team account',
          'dedicated-clusters': 'Dedicated clusters',
          'advanced-statistics': 'Advanced statistics',
          'statistics': 'Statistics',
          'custom-rollups': 'Custom AppChains'
        },
      }
    },
    "most-popular": "MOST POPULAR",
    "starting-at": "Starting at",
    "your-current-plan": "Your Current Plan",
    "continue": "Continue",
  }
}
