<script lang="ts" setup>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import { GetBlockUIButton, GetBlockUIIcon, GetBlockUILoader } from '@getblockui/core';
import { useI18n } from 'vue-i18n';
import { usePaymentProcessStore } from '@/features/paymentProcess';
import { computed, defineAsyncComponent } from 'vue';
import { useAccessTokenStore } from '@/entities/accessToken';
import { useGetCurrentPlan } from '@/features/currentPlan/useGetCurrentPlan';

const PaymentProcessModalComponents = {
  SelectPaymentMethod: defineAsyncComponent(
    () => import('@/features/paymentProcess/ui/paymentProcessModal/parts/SelectPaymentMethod.vue'),
  ),
  UpgradePlan: defineAsyncComponent(
    () => import('@/features/paymentProcess/ui/paymentProcessModal/parts/UpgradePlan.vue'),
  ),
  TopUp: defineAsyncComponent(() => import('@/features/paymentProcess/ui/paymentProcessModal/parts/TopUp.vue')),
};

const { t } = useI18n();
const paymentProcessStore = usePaymentProcessStore();
const accessTokensStore = useAccessTokenStore();
const { getCurrentPlan } = useGetCurrentPlan();

const isVisibleBackButton = computed(
  () =>
    paymentProcessStore.getPaymentProcessModalHistory[paymentProcessStore.getPaymentProcessModalHistory.length - 2] !==
    '',
);

const getComponent = computed(() => {
  //@ts-ignore
  return PaymentProcessModalComponents[paymentProcessStore.getPaymentProcessModalComponent];
});

const isInfoVisible = computed(() => {
  return (
    !isVisibleBackButton.value &&
    (paymentProcessStore.getPaymentProcessModalInfo === 'statistics' ||
      paymentProcessStore.getPaymentProcessModalInfo === 'team-members' ||
      paymentProcessStore.getPaymentProcessModalInfo === 'top-up-available')
  );
});

const nextStepButtonText = computed(() => {
  if (paymentProcessStore.getPaymentProcessModalComponent === 'TopUp') {
    if (paymentProcessStore.getSelectedPlan?._key === 'pro') {
      return t(`paymentProcess.paymentProcessModal.buttons.get-pro`);
    }
    return t(`paymentProcess.paymentProcessModal.buttons.top-up`);
  }
  if (paymentProcessStore.getPaymentProcessModalComponent === 'SelectPaymentMethod') {
    return t(`paymentProcess.paymentProcessModal.buttons.proceed-checkout`);
  } else return t(`paymentProcess.paymentProcessModal.buttons.upgrade`);
});

const currentSharedTokensAmount = computed(() => {
  const sharedAccessTokens = accessTokensStore.getSharedAccessTokens;
  return sharedAccessTokens.length;
});

const currentPlanMaxAmountOfTokens = computed(() => {
  return getCurrentPlan.value.max_token_amount;
});

const showTokenLimitWarning = computed(() => {
  return (
    paymentProcessStore.getPaymentProcessModalInfo === 'tokens-amount' &&
    currentSharedTokensAmount.value >= currentPlanMaxAmountOfTokens.value
  );
});

const back = () => {
  paymentProcessStore.backInHistoryComponent();
};
const close = () => {
  paymentProcessStore.setPaymentProcessModalInfo(null);
  paymentProcessStore.setPaymentProcessModalDisplay(false);
};

const callApiForBuyPlan = async () => {
  await paymentProcessStore.requestPay();
};

const nextStep = () => {
  if (paymentProcessStore.getPaymentProcessModalComponent === 'SelectPaymentMethod') callApiForBuyPlan();
  else paymentProcessStore.setPaymentProcessModalComponent('SelectPaymentMethod');
};
</script>

<template>
  <ModalTemplate additional-classes="max-w-[400px] w-[400px]" paddings="p-6" rounded="rounded-24" @close="close">
    <div class="w-full flex flex-col">
      <header class="flex items-center justify-between mb-8">
        <GetBlockUIButton
          :class="{ 'opacity-0': !isVisibleBackButton }"
          icon-button
          size-type="small"
          color-type="secondary"
        >
          <GetBlockUIIcon icon-name="chevron-left" @click="back" />
        </GetBlockUIButton>
        <p class="text-sm gb-text-text-main leading-5 font-bold">
          {{ t(`paymentProcess.paymentProcessModal.title.${paymentProcessStore.getPaymentProcessModalComponent}`) }}
        </p>
        <GetBlockUIButton icon-button size-type="small" color-type="secondary" @click="close">
          <GetBlockUIIcon icon-name="x-close" />
        </GetBlockUIButton>
      </header>
      <p v-if="isInfoVisible" class="text-sm font-medium text-center mb-4">
        {{ t(`paymentProcess.paymentProcessModal.info.${paymentProcessStore.getPaymentProcessModalInfo}`) }}
      </p>
      <div v-if="showTokenLimitWarning" class="flex items-center py-2 px-3 gap-3 rounded-[8px] bg-[#FFF0F0] mb-8">
        <p class="font-bold text-[20px] leading-6 gb-text-text-tint-red flex-shrink-0">
          {{ currentSharedTokensAmount }}<span class="opacity-30">/{{ currentPlanMaxAmountOfTokens }}</span>
        </p>
        <p class="gb-text-text-tint-red text-xs font-medium leading-4">
          {{ t('shared-nodes.modals.upgrade-plan.tokens-warning') }}
        </p>
      </div>
      <component :is="getComponent" />
      <GetBlockUIButton class="mt-8 flex justify-center" @click="nextStep">
        <GetBlockUILoader class="m-auto" v-if="paymentProcessStore.getProcessOfReceivingPaymentLink" />
        <span v-else>{{ nextStepButtonText }}</span>
      </GetBlockUIButton>
    </div>
  </ModalTemplate>
</template>
