import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/store';
import { getStatisticsByParameters, getStatisticsByTokens, getStatisticsPreviewByTokens } from '@/api/statistics';
import { AppNotificationModule } from '@/application';
import { StatisticsActions, StatisticsGetters, StatisticsMutations, StatisticsState } from '@/types/statistics/store';

export type State = StatisticsState;
export type Mutations = StatisticsMutations;
export type Getters = StatisticsGetters;
export type Actions = StatisticsActions;

const state: () => State = () => ({
  statisticsPreview: {
    'shared': {},
    'dedicated': {},
  },
  // note: requests and rejections statisctics 21 days for dashbord widgets charts
  threeWeeksStatistics: {
    'shared': {
      rateLimitRejections: [],
      requests: [], 
    },
    'dedicated': {
      rateLimitRejections: [],
      requests: [], 
    },
  },
  requestUsageStatistics: {
    data: {},
    total: 0,
  },
  rateLimitRejectionsStatistics: {
    data: {},
    total: 0,
  },
  responseStatusesStatistics: [],
  methodCallsStatistics: [],
});

const getters: GetterTree<State, RootState> & Getters = {};

const mutations: MutationTree<State> & Mutations = {
  setStatisticsPreview(state, { statistics, namespace }) {
    state.statisticsPreview = { ...state.statisticsPreview, [namespace]: statistics };
  },
  setThreeWeeksStatistics(state, { statistics, namespace }) {
    state.threeWeeksStatistics[namespace] = {
      rateLimitRejections: statistics.rateLimitRejections,
      requests: statistics.requests,
    };
  },
  setStatistics(state, statistics) {
    state.requestUsageStatistics = {
      data: statistics.data?.total_requests?.requests || {},
      total: statistics.data?.total_requests?.total || 0,
    };
    state.rateLimitRejectionsStatistics = {
      data: statistics.data?.by_status_code['429']?.requests || {},
      total: statistics.data?.by_status_code['429']?.total || 0,
    };
    state.responseStatusesStatistics = statistics.data?.by_status_code ? Object.keys(statistics.data?.by_status_code).map((code) => ({
      name: code,
      // @ts-ignore
      data: statistics.data?.by_status_code[code].requests,
      // @ts-ignore
      total: statistics.data?.by_status_code[code].total,
    })) : [];
    state.methodCallsStatistics = statistics.data?.by_method ? Object.keys(statistics.data?.by_method).map((method) => ({
      name: method,
      // @ts-ignore
      data: statistics.data?.by_method[method]?.requests,
      // @ts-ignore
      total: statistics.data?.by_method[method]?.total,
    })) : [];
  },
};

const actions: ActionTree<State, RootState> & Actions = {
  async getStatisticsByParameters({ commit }, {
    protocols,
    networks,
    apis,
    addons,
    duration,
    namespace,
    recaptchaToken,
  }) {
    try {
      const statistics = await getStatisticsByParameters(protocols, networks, apis, addons, duration, namespace, recaptchaToken);
      commit('setStatistics', statistics);
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      const notificationStore = AppNotificationModule.useNotificationStore();
      notificationStore.setNotificationMessage(  { ...errorData('general_get-statistics'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true);
    }
  },
  async getStatisticsByTokens({ commit }, { accessTokens, duration, namespace, recaptchaToken }) {
    try {
      const statistics = await getStatisticsByTokens(accessTokens, duration, namespace, recaptchaToken);
      commit('setStatistics', statistics);
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      const notificationStore = AppNotificationModule.useNotificationStore();
      notificationStore.setNotificationMessage(  { ...errorData('general_get-statistics'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true);
    }
  },
  async getPreviewTokensStatistics({ commit }, { accessTokens, namespace, recaptchaToken }) {
    try {
      const statistics = await getStatisticsPreviewByTokens(accessTokens, namespace, recaptchaToken);
      const { rate_limit_rejections: rateLimitRejections, requests, tokens } = statistics.data;
      if (tokens) commit('setStatisticsPreview', { statistics: tokens, namespace });

      if (rateLimitRejections && requests) {
      commit('setThreeWeeksStatistics', { 
        statistics: {
          rateLimitRejections: rateLimitRejections.slice(-21), 
          requests: requests.slice(-21),
        }, 
        namespace 
      });
    }
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      const notificationStore = AppNotificationModule.useNotificationStore();
      notificationStore.setNotificationMessage(  { ...errorData('general_get-statistics'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true);
    }
  },
};

const endpoints: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
};

export default endpoints;


