import { PRSActions, RPSGetters, RPSMutations, RPSState } from '@/types/rps/store';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/store';
import { AppNotificationModule } from '@/application';
import { fetchRPS } from '@/entities/rps/api';

export type State = RPSState;
export type Mutations = RPSMutations;
export type Getters = RPSGetters;
export type Actions = PRSActions;

const state: () => State = () => ({
  userRPSInfo: {
    user_id: '',
    rps: 0
  }
});

const getters: GetterTree<State, RootState> & Getters = {};

const mutations: MutationTree<State> & Mutations = {
  setUserRPSInfo(state, payload) {
    state.userRPSInfo = payload
  }
}

const actions: ActionTree<State, RootState> & Actions = {
  async getUserRPSInfo({commit}) {
    try {
      const { data } = await fetchRPS()
      commit('setUserRPSInfo', data)
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      const notificationStore = AppNotificationModule.useNotificationStore();
      notificationStore.setNotificationMessage(  { ...errorData('general_get-pricing'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true);
    }
  }
}

const rps: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
};

export default rps;