import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { TeamsActions, TeamsGetters, TeamsMutations, TeamsState } from '@/types/teams/store';
import { RootState } from '@/types/store';
import {
  getTeamList, 
  getInviteList, 
  getCurrentTeam,
  teamAuth, 
  renameTeam, 
  removeMember, 
  renameMember,
  getIsAllowedCreateTeam
} from '@/api/teams';
import { TeamListItem, TeamListItemWithAvatar, Team, InviteListItem } from '@/types/teams/teams';
import { AppNotificationModule } from '@/application';
import { setAvatar } from '@/utils/setAvatar';

export type State = TeamsState;
export type Mutations = TeamsMutations;
export type Getters = TeamsGetters;
export type Actions = TeamsActions;

const state: () => State = () => ({
  modals: {
    showCreateTeamModal: false,
    showAddMemberModal: false,
    showAddMemberSuccessModal: false,
    showRemoveMemberModal: false,
    showEditMemberModal: false,
    showAccessDeniedModal: false
  },
  teamList: [],
  currentTeamId: sessionStorage.getItem('currentTeamId') || '',
  teamToken: sessionStorage.getItem('teamToken') || '',
  currentTeam: {
    id: '',
    name: '',
    avatar: '',
    members: []
  },
  inviteList: [],
  isAllowedCreateTeam: false
});

const getters: GetterTree<State, RootState> & Getters = {
  isPersonalAccount(state) {
    return !state.currentTeamId;
  },
  userTeamPermissions(state, getters, rootState) {
    return state.currentTeam.members.find((member) => member.id === rootState.user.id)?.permissions || [];
  },
  userTeamRole (state, getters, rootState) {
    return state.currentTeam.members.find((member) => member.id === rootState.user.id)?.role || '';
  },
  // @ts-ignore
  hasPaymentPermission(state, getters) {
    // @ts-ignore
    return getters.isPersonalAccount || getters.userTeamPermissions.includes('payment')
  },
  // @ts-ignore
  hasAccessTokensPermission(state, getters) {
    // @ts-ignore
    return getters.isPersonalAccount || getters.userTeamPermissions.includes('access_tokens')
  }
};

const mutations: MutationTree<State> & Mutations = {
  setShowCreateTeamModal(state, show) {
    state.modals.showCreateTeamModal = show;
  },
  setShowAddMemberModal(state, show) {
    state.modals.showAddMemberModal = show;
  },
  setShowAddMemberSuccessModal(state, show) {
    state.modals.showAddMemberSuccessModal = show;
  },
  setShowRemoveMemberModal(state, show) {
    state.modals.showRemoveMemberModal = show;
  },
  setShowEditMemberModal(state, show) {
    state.modals.showEditMemberModal = show;
  },
  setShowAccessDeniedModal(state, show) {
    state.modals.showAccessDeniedModal = show;
  },
  setTeamList(state, teams) {
    state.teamList = teams;
  },
  addTeam(state, team) {
    state.teamList.push(team);
  },
  setCurrentTeamId(state, teamId) {
    state.currentTeamId = teamId;
    sessionStorage.setItem('currentTeamId', teamId);
  },
  setTeamToken(state, token) {
    state.teamToken = token;
    sessionStorage.setItem('teamToken', token);
  },
  setCurrentTeam(state, team) {
    state.currentTeam = team;
  },
  setInviteList(state, invites) {
    state.inviteList = invites;
  },
  resolveInvite(state, teamId) {
    state.inviteList = state.inviteList.filter(invite => invite.id !== teamId);
  },
  renameTeam(state, name ) {
    state.currentTeam.name = name;
  },
  addMember(state, member) {
    state.currentTeam.members.push(member);
  },
  removeMember(state, memberUid) {
    state.currentTeam.members = state.currentTeam.members.filter(member => member.id !== memberUid);
  },
  renameMember(state, { name, memberUid }) {
    state.currentTeam.members = state.currentTeam.members.map((member) => member.id === memberUid ? {
      ...member,
      name,
    } : member);
  },
  setIsAllowedCreateTeam(state, allowed) {
    state.isAllowedCreateTeam = allowed
  }
};

const actions: ActionTree<State, RootState> & Actions = {
  setCurrentTeamId({ commit }, teamId) {
    commit('setCurrentTeamId', teamId);
    if (!teamId) {
      commit('setTeamToken', '');
      commit('setCurrentTeam', {
        id: '',
        name: '',
        avatar: '',
        members: [],
      });
    }
  },
  async getTeamList({ commit } ) {
    try {
      const { data } = await getTeamList();
      const teamList = data.map((team: TeamListItem) => ({
        ...team,
        avatar: setAvatar(team.id)
      }));
      commit('setTeamList', teamList as TeamListItemWithAvatar[]);
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      const notificationStore = AppNotificationModule.useNotificationStore();
      notificationStore.setNotificationMessage(  { ...errorData('teams_get-team-list'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true);
    }
  },
  async teamAuth({ dispatch, commit, state }, recaptchaToken ) {
    if (state.currentTeamId) {
      try {
        const { data } = await teamAuth(state.currentTeamId, recaptchaToken);
        commit('setTeamToken', data);
      } catch (e) {
        dispatch('setCurrentTeamId', '');
        const { errorData } = AppNotificationModule.useNotification();
        const notificationStore = AppNotificationModule.useNotificationStore();
        notificationStore.setNotificationMessage(  { ...errorData('teams_team-auth'), type: 'error' });
        notificationStore.setNotificationMessageDisplay(true);
      }
    }
  },
  async getCurrentTeam({ dispatch, commit, state} ) {
    try {
      const { data } = await getCurrentTeam(state.currentTeamId);
      commit('setCurrentTeam', data as Team);
    } catch (e) {
      dispatch('setCurrentTeamId', '');
      // @ts-ignore
      if (e.response.status === 403 && e.response.data.includes('user has no access uid')) {
        const { attentionData } = AppNotificationModule.useNotification();
        const notificationStore = AppNotificationModule.useNotificationStore();
        notificationStore.setNotificationMessage(  { ...attentionData('teams_no-access-uid'), type: 'attention' });
        notificationStore.setNotificationMessageDisplay(true);
      } else {
        const { errorData } = AppNotificationModule.useNotification();
        const notificationStore = AppNotificationModule.useNotificationStore();
        notificationStore.setNotificationMessage(  { ...errorData('teams_get-current-team'), type: 'error' });
        notificationStore.setNotificationMessageDisplay(true);
      }
    }
  },
  async getInviteList({ commit } ) {
    try {
      const { data } = await getInviteList();
      commit('setInviteList', data as InviteListItem[]);
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      const notificationStore = AppNotificationModule.useNotificationStore();
      notificationStore.setNotificationMessage(  { ...errorData('teams_get-invite-list'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true);
    }
  },
  async renameTeam({ commit, state }, { name, recaptchaToken }) {
    try {
      await renameTeam(name, state.currentTeamId, recaptchaToken);
      commit('renameTeam', name);
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      const notificationStore = AppNotificationModule.useNotificationStore();
      notificationStore.setNotificationMessage(  { ...errorData('teams_rename-team'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true);
    }
  },
  async removeMember({ commit, state },  { memberUid, recaptchaToken }) {
    try {
      await removeMember(memberUid, state.currentTeamId, recaptchaToken);
      commit('removeMember',  memberUid);
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      const notificationStore = AppNotificationModule.useNotificationStore();
      notificationStore.setNotificationMessage(  { ...errorData('teams_remove-member'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true);
    }
  },
  async renameMember({ commit, state }, { name, memberUid, recaptchaToken }) {
    try {
      await renameMember(name, state.currentTeamId, recaptchaToken);
      commit('renameMember', { name, memberUid });
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      const notificationStore = AppNotificationModule.useNotificationStore();
      notificationStore.setNotificationMessage(  { ...errorData('teams_rename-member'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true);
    }
  },
  async getIsAllowedCreateTeam({ commit } ) {
    try {
      const { data } = await getIsAllowedCreateTeam();
      commit('setIsAllowedCreateTeam', data);
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      const notificationStore = AppNotificationModule.useNotificationStore();
      notificationStore.setNotificationMessage(  { ...errorData('teams_info'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true);
    }
  },
};

const TeamsModule: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
};

export default TeamsModule;
