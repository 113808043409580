import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col gap-3"
}
const _hoisted_2 = { class: "flex justify-between items-center" }
const _hoisted_3 = { class: "leading-5 gb-text-text-main" }
const _hoisted_4 = { class: "font-bold me-1" }
const _hoisted_5 = { class: "font-medium" }
const _hoisted_6 = {
  key: 0,
  class: "font-medium text-sm gb-text-text-secondary"
}
const _hoisted_7 = { class: "leading-5 gb-text-text-main" }
const _hoisted_8 = { class: "font-bold me-1" }
const _hoisted_9 = { class: "font-medium" }
const _hoisted_10 = { class: "leading-5 gb-text-text-main" }
const _hoisted_11 = { class: "font-bold me-1" }
const _hoisted_12 = { class: "font-medium" }
const _hoisted_13 = {
  key: 1,
  class: "flex flex-col gap-3 h-[64px]"
}
const _hoisted_14 = { class: "flex justify-between items-center" }
const _hoisted_15 = { class: "leading-5 gb-text-text-main" }
const _hoisted_16 = { class: "font-bold me-1" }
const _hoisted_17 = { class: "leading-5 gb-text-text-main" }
const _hoisted_18 = { class: "font-bold me-1" }
const _hoisted_19 = { class: "font-medium" }

import { computed, PropType } from 'vue';
import {
  CUPlansIdType,
  DedicatedPlanType,
  EnterprisePlanCUType,
  ProPlanCUType,
  StarterPlanCUType,
} from '@/shared/products/types';
import { formatNumberCompact } from '@/utils/changeNumberDisplay';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'PricingCardParametrs',
  props: {
  cuAmount: {
    type: Number,
    default: 0
  },
  rps: {
    type: Number,
    default: 0
  },
  accessTokens: {
    type: Number,
    default: 0
  },
  price: {
    type: Number,
    default: 0
  },
  planId: {
    type: String as PropType<CUPlansIdType<StarterPlanCUType | ProPlanCUType | EnterprisePlanCUType | DedicatedPlanType>>,
    default: ''
  },
  basePrice: {
    type: Number,
    default: 0
  },
},
  setup(__props) {

const props = __props;

const { t } = useI18n();

const CUAmount = computed(() => props.planId === 'plans/enterprise_plan' ? t('pricing-card.parameters.custom') : formatNumberCompact(props.cuAmount));
const RPS = computed(() => props.planId === 'plans/enterprise_plan' ?  t('pricing-card.parameters.custom') : props.rps);
const accessTokens = computed(() => props.planId === 'plans/enterprise_plan' ?  t('pricing-card.parameters.custom') : props.accessTokens)


return (_ctx: any,_cache: any) => {
  return (__props.planId !== "plans/dedicated")
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(CUAmount.value), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('pricing-card.parameters.cu-month')), 1)
          ]),
          (props.planId !== 'plans/enterprise_plan' && props.planId !== 'plans/dedicated' && props.planId !== 'plans/free')
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                _createElementVNode("span", null, "$" + _toDisplayString((( __props.basePrice / __props.cuAmount ) * 1000000).toFixed(2)), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('pricing-card.parameters.1m-cu')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("p", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, _toDisplayString(RPS.value), 1),
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('pricing-card.parameters.rps')), 1)
        ]),
        _createElementVNode("p", _hoisted_10, [
          _createElementVNode("span", _hoisted_11, _toDisplayString(accessTokens.value), 1),
          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('pricing-card.parameters.access-token')), 1)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("p", _hoisted_15, [
            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('pricing-card.parameters.unlimited')), 1),
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "font-medium" }, "CU", -1))
          ])
        ]),
        _createElementVNode("p", _hoisted_17, [
          _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t('pricing-card.parameters.up-to')) + ": 1000", 1),
          _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('pricing-card.parameters.rps')), 1)
        ])
      ]))
}
}

})