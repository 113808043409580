import { computed } from 'vue';
import { useStore } from '@/store';
import { IEnterpriseSubscription } from '@/entities/subscriptions/types';
import { ICurrentPlan } from '@/features/currentPlan/types';
import { usePaymentProcessStore } from '@/features/paymentProcess';

const FreePlan: ICurrentPlan = {
  type: 'free',
  name: 'Free',
  rps: 5,
  cu_amount: 50000,
  max_token_amount: 2,
  expired: '',
  subscriptionId: 'subscription/',
};
export function useGetCurrentPlan() {

  const store = useStore();
  const paymentStore = usePaymentProcessStore();

  const getCurrentPlan = computed<ICurrentPlan>(() => {
    const sharedActiveSubscription = store.state.subscriptions.subscriptionsList.find((subscription) => (subscription.status === 'active' || subscription.status === 'grace_period') && (subscription.service.product_type === 'plan' || subscription.service.product_type === 'enterprise_plan'));
    if (!sharedActiveSubscription) return FreePlan;
    // Set Current Subscription ID to store
    paymentStore.setCurrentSubscriptionId(sharedActiveSubscription._id);
    // ENTERPRISE
    const isEnterprisePlan = sharedActiveSubscription.service.product_type === 'enterprise_plan';
    const enterpriseSubscription = sharedActiveSubscription as IEnterpriseSubscription;
    if (isEnterprisePlan) {
      return {
        type: enterpriseSubscription.service.product_type,
        name: 'Enterprise',
        subscriptionId: enterpriseSubscription._id,
        rps: enterpriseSubscription.service.enterprise_plan_configuration?.rps,
        cu_amount: enterpriseSubscription.service.enterprise_plan_configuration?.cu_amount,
        max_token_amount: enterpriseSubscription.service.enterprise_plan_configuration?.max_token_amount,
        expired: enterpriseSubscription.current_period_end,
      }
    }
    //
    const currentPlan = [...store.state.packages.startPlans, ...store.state.packages.proPlan, ...store.state.packages.enterprisePlans].find((plan) => plan._id === sharedActiveSubscription.service.id);
    if (!currentPlan) return FreePlan
    return {
      type: currentPlan._key, // TODO
      name: currentPlan.name,
      subscriptionId: sharedActiveSubscription._id,
      // @ts-ignore
      rps: store.state.rps.userRPSInfo.rps,
      cu_amount: currentPlan.cu_amount,
      max_token_amount: currentPlan.max_token_amount,
      expired: sharedActiveSubscription.current_period_end,
    }
  });

  return {
    getCurrentPlan
  }
}
