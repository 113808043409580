import axios from 'axios';
import { EmailAndPassword, UtmObject } from '@/types/auth/shared';

const AXIOS_CONFIG = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const AXIOS_CONFIG_AWS = {
  baseURL: process.env.VUE_APP_API_URL_AWS,
  headers: {
    'Content-Type': 'application/json',
  },
};

const httpClient = axios.create(AXIOS_CONFIG);

const httpClientAws = axios.create(AXIOS_CONFIG_AWS);

httpClient.interceptors.response.use(
  response => response,
  error => {
    const config: { method: string; url: string; } = error.config;
    console.error(`[${config.method.toUpperCase()}] ${config.url} error: ${error}`);
    return Promise.reject(error);
  },
);

const recoverPassword = (email: string, recaptchaToken: string) =>
  httpClient.post(
    `/password/drop/${email}`,
    {},
    {
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    },
  );

const loginGithub = (data: {
  referral_user?: string | null;
  code: string;
  user_utm?: UtmObject;
  team_invite?: string | null;
  recaptchaToken: string;
}) => {
  return httpClientAws.post('/github', { ...data }, {
    headers: {
      'recaptcha-token': data.recaptchaToken,
    },
  });
};

const getNonceMetamask = (address: string, recaptchaToken: string, invite?: string | null) => {
  const data = {
    action: 'get_nonce',
    params: {
      wallet_address: address,
      team_invite: invite,
    },
  };
  return httpClientAws.post('/metamask', data, {
    headers: {
      'recaptcha-token': recaptchaToken,
    },
  });
};

const signUpMetamask = (address: string, recaptchaToken: string, referral_user?: string | null, utm?: UtmObject, invite?: string | null) => {
  const data = {
    action: 'signup',
    params: {
      wallet_address: address,
      referral_user,
      user_utm: utm,
      team_invite: invite,
    },
  };
  return httpClientAws.post('/metamask', data, {
    headers: {
      'recaptcha-token': recaptchaToken,
    },
  });
};

const signInMetamask = (address: string, signature: string, nonce: string, recaptchaToken: string, utm?: UtmObject) => {
  const data = {
    action: 'login',
    params: {
      wallet_address: address,
      signature: signature,
      nonce: nonce,
      user_utm: utm,
    },
  };
  return httpClientAws.post('/metamask', data, {
    headers: {
      'recaptcha-token': recaptchaToken,
    },
  });
};

const signUpEmail = (params: EmailAndPassword, recaptchaToken: string) => {
  return httpClientAws.post(
    '/basic',
    {
      action: 'signup',
      params,
    },
    {
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    });
};

const signInEmail = (params: EmailAndPassword, recaptchaToken: string) => {
  return httpClientAws.post(
    '/basic',
    {
      action: 'login',
      params,
    }, {
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    });
};

const googleLogin = (data: {
  referral_user?: string | null;
  token?: string;
  user_utm?: UtmObject;
  team_invite?: string | null;
  recaptchaToken: string;
}) =>
  httpClientAws.post('/google', {
    ...data,
  }, {
    headers: {
      'Content-Type': 'text/plain',
      'recaptcha-token': '8TepKyLcOg0Ihzee6L28Dp1MLJno35ME',
    },
  });

const forgotPassword = (username: string, recaptchaToken: string, accountDefinitionType: 'username' | 'uid') => {
  return httpClientAws.post('/basic',
    {
      action: 'recovery',
      params: { [accountDefinitionType]: username },
    },
    {
      headers: {
        'recaptcha-token': '8TepKyLcOg0Ihzee6L28Dp1MLJno35ME',
      },
    });
};

const changePassword = (password: string, token: string, recaptchaToken: string) => {
  const data = {
    action: 'recovery',
    params: {
      password,
    },
  };
  return httpClientAws.put(
    '/basic',
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'recaptcha-token': '8TepKyLcOg0Ihzee6L28Dp1MLJno35ME',
      },
    },
  );
};

const confirmEmail = (token: string, recaptchaToken: string) => {
  return httpClientAws.put(
    '/basic',
    {
      action: 'confirm',
      params: {},
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'recaptcha-token': '8TepKyLcOg0Ihzee6L28Dp1MLJno35ME',
      },
    },
  );
};

export {
  signUpEmail,
  changePassword,
  signInEmail,
  confirmEmail,
  recoverPassword,
  forgotPassword,
  googleLogin,
  getNonceMetamask,
  signUpMetamask,
  signInMetamask,
  loginGithub,
};
