export default {
  "notifying-banner": {
    'before-price-change': {
      'title': "新定价即将公布！",
      'subscription': {
        1: '专用节点价格正在上涨',
        2: '在变更之前锁定您的当前利率！'
      }
    },
    'after-price-change': {
      'title': "专用节点定价更新！",
      'subscription': {
        1: '我们的新定价现已生效。',
        2: '在我们的最新博客文章中探索详细信息。'
      }
    }
  }
}