import { SharedApiModule } from '@/shared'
import store from '@/store';
import { AxiosResponse } from 'axios';
import {
  IChangePeriodOption,
  IDedicatedSubscription,
  ISharedSubscription,
  SubscriptionIdType,
} from '@/entities/subscriptions/types';

const fetchSubscriptions = () => SharedApiModule.HttpClients.httpClientV6.get('/subscription/', {
    headers: {
      Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
    },
  },
) as Promise<AxiosResponse<(ISharedSubscription | IDedicatedSubscription)[]>>

const fetchPrepareNextPeriodOrder = (subscriptionId: SubscriptionIdType) => SharedApiModule.HttpClients.httpClientV6.post(
  '/subscription/prepare-next-period',
  {
    subscription_id: subscriptionId
  },
  {
  headers: {
    Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
  },
}) as Promise<AxiosResponse<{ticket_get_payment_link: string; updated_subscription: IDedicatedSubscription}>>


const fetchChangePeriodSubscription = (changePeriodOption: IChangePeriodOption) => SharedApiModule.HttpClients.httpClientV6.post(
  '/subscription/change-period',
  changePeriodOption,
  {
    headers: {
      Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
    },
  }
) as Promise<AxiosResponse<{ticket_get_payment_link: string; updated_subscription: IDedicatedSubscription}>>

export { fetchSubscriptions, fetchPrepareNextPeriodOrder, fetchChangePeriodSubscription }