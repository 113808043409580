import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { ICUPackage } from '@/entities/requestPackage/types';
import { CUPackageType } from '@/shared/products/types';
import { fetchPacks } from '@/entities/requestPackage/api';

const usePackagePrivateStore = defineStore('packagePrivate', () => {
  const packs = ref<ICUPackage<CUPackageType>[]>([]);

  const setPack = (pack: ICUPackage<CUPackageType>) => {
    packs.value.push(pack)
  }

  const clearPacks = () => {
    packs.value = [];
  }

  return {
    packs,
    setPack,
    clearPacks,
  }
})

export const usePackageStore = defineStore('package', () => {
  const privateStore = usePackagePrivateStore();

  const sortPacks = computed(() => {
    return privateStore.packs.sort((a,b) => a.cu_amount - b.cu_amount)
  });
  const getPacks = computed(() => sortPacks.value.filter((pack) => !pack.is_archived));
  const getAllPacks = computed(() => sortPacks.value)

  const requestPacks = async () => {
    try {
      const response = await fetchPacks();
      privateStore.clearPacks();
      Object.values(response.data).forEach((pack) => {
        if (pack._id.includes('topup')) privateStore.setPack(pack as ICUPackage<CUPackageType>)
      })
    } catch (e) {
      console.log(e);
    }
  }

  return {
    getPacks,
    getAllPacks,
    requestPacks
  }
})
