<script setup lang="ts">
import { GetBlockUIButton, GetBlockUIIcon } from '@getblockui/core';
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import { defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
import { useGetCurrentPlan } from '@/features/currentPlan/useGetCurrentPlan';

const emits = defineEmits(['close']);
const { t } = useI18n();

const { getCurrentPlan } = useGetCurrentPlan();

const getDateFromSQLDate = (sqlDate: string) => {
  if (sqlDate) {
    const dateParts = sqlDate.split('-');
    return new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2].substring(0, 2));
  }

  return new Date();
};

const formatDate = (
  sqlDate: string,
  yearFormat: 'numeric' | '2-digit' = '2-digit',
  monthFormat: 'short' | 'numeric' | '2-digit' | 'long' | 'narrow' = 'short',
) => {
  const date = getDateFromSQLDate(sqlDate);

  return date.toLocaleString('en-US', {
    year: yearFormat,
    month: monthFormat,
    day: 'numeric',
  });
};

const close = () => {
  emits('close');
};
</script>

<template>
  <ModalTemplate additional-classes="max-w-[400px] w-[400px]" paddings="p-6" rounded="rounded-24" @close="close">
    <div class="w-full flex flex-col">
      <header class="flex items-center mb-8">
        <p class="pl-[36px] ml-auto text-sm gb-text-text-main leading-5 font-bold">
          {{ t(`downgrade-modal.title`) }}
        </p>
        <GetBlockUIButton class="ml-auto" icon-button size-type="small" color-type="secondary" @click="close">
          <GetBlockUIIcon icon-name="x-close" />
        </GetBlockUIButton>
      </header>
      <p class="font-bold text-[18px] leading-6 text-center mb-2">
        {{ t(`downgrade-modal.subtitle.1`) }}{{ getCurrentPlan.name }}{{ t(`downgrade-modal.subtitle.2`)
        }}{{ formatDate(getCurrentPlan.expired) }}
      </p>
      <p class="font-medium text-sm text-center">{{ t(`downgrade-modal.text`) }}</p>
      <GetBlockUIButton class="justify-center mt-8" @click="close">Ok</GetBlockUIButton>
    </div>
  </ModalTemplate>
</template>

<style scoped lang="scss"></style>
