import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import {
  IPaymentProcessModalOption,
  ISelectedPaymentProvider,
  ISelectPeriodItem, PaymentProcessModalComponentTypesType, PaymentProcessModalHistoryType,
  SelectPaymentProviderType,
  SelectPeriodType,
  PaymentProcessModalInfoTypesType,
} from './types';
import { PeriodCUTypesType } from '@/shared/service/types';
import { PaymentModalTypesType, PaymentProviderTypesType } from '@/shared/payment/types';
import { ICUPlan } from '@/entities/sharedPlans/types';
import { CUPackageType, ProPlanCUType, StarterPlanCUType } from '@/shared/products/types';
import {
  additionalServiceCreate,
  fetchSubscriptionCreate,
  sseObserverPayLink,
} from '@/entities/subscriptionCreate/api';
import { useRouter } from 'vue-router';
import { ICUPackage } from '@/entities/requestPackage/types';
import { usePackageStore } from '@/entities/requestPackage/store';
import { useGetCurrentPlan } from '@/features/currentPlan/useGetCurrentPlan';

const usePaymentProcessPrivateStore = defineStore('paymentProcessPrivate', () => {
  const Periods: Readonly<SelectPeriodType> = [
    {
      text: 'Monthly',
      value: '1m',
    },
    {
      text: 'Annually',
      value: '12m',
    },
  ];
  const PaymentProviders: Readonly<SelectPaymentProviderType> = [
    {
      text: 'Credit Card',
      value: 'paddle',
      paymentModel: 'recurrent',
    },
    {
      text: 'Crypto',
      value: 'nowpayments',
      paymentModel: 'onetime',
    },
  ];
  const inProcessOfReceivingPaymentLink = ref<boolean>(false);
  const explicitConsentModalDisplay = ref<boolean>(false);

  const paymentProcessModalHistory = ref<PaymentProcessModalHistoryType>(['']);
  const paymentProcessModalOption = ref<IPaymentProcessModalOption>({
    display: false,
    component: '',
  });
  const selectedPeriod = ref<ISelectPeriodItem<PeriodCUTypesType>>(Periods[0]);
  const selectedPaymentProviders = ref<ISelectedPaymentProvider<PaymentProviderTypesType>>(PaymentProviders[0]);
  const paymentProcessModalInfo = ref<PaymentProcessModalInfoTypesType | null>(null);

  const selectedPlan = ref<ICUPlan<StarterPlanCUType | ProPlanCUType> | null>(null);
  const selectedCUPack = ref<ICUPackage<CUPackageType> | null>(null);
  const currentSubscriptionId = ref<string>('');

  const ticketGetPaymentLink = ref<string>('');
  const redirectToCheckoutOption = ref<{
    paymentLink: string,
    provider: PaymentProviderTypesType | ''
  }>({
    paymentLink: '',
    provider: '',
  });

  const getPaymentConfig = computed(() => {
    if (selectedPlan.value) {
      return {
        payment_model: selectedPaymentProviders.value.paymentModel,
        payment_provider: selectedPaymentProviders.value.value,
        service: {
          product_type: 'plan',
          period: selectedPeriod.value.value,
          id: selectedPlan.value._id,
        },
      }
    } else if (selectedCUPack.value) {
      return {
        subscription_id: currentSubscriptionId.value,
        payment_provider: selectedPaymentProviders.value.value,
        service: {
          product_type: 'request_package',
          id: selectedCUPack.value._id,
          subscription_id: currentSubscriptionId.value
        },
      }
    } else {
      console.error('selectedPlan or selectedPlan not select');
      return null
    }
  });

  const pushHistoryInPaymentProcessModalHistory = (history: PaymentProcessModalComponentTypesType) => {
    if (paymentProcessModalHistory.value.includes(history)) {
      const indexCurrentHistoryItem = paymentProcessModalHistory.value.indexOf(history);
      if (indexCurrentHistoryItem === paymentProcessModalHistory.value.length - 1) return;
      else {
        paymentProcessModalHistory.value = ['', history];
        return;
      }
    }
    paymentProcessModalHistory.value.push(history);
  };
  const clearHistoryComponent = () => {
    paymentProcessModalHistory.value = ['']
  }
  const setTicketGetPaymentLink = (ticket: string) => {
    ticketGetPaymentLink.value = ticket;
  };
  const setExplicitConsentModalDisplay = (display: boolean) => {
    explicitConsentModalDisplay.value = display
  }
  const setRedirectToCheckoutLink = (link: string) => {
    redirectToCheckoutOption.value.paymentLink = link
  };
  const setRedirectToCheckoutProvider = (provider: PaymentProviderTypesType) => {
    redirectToCheckoutOption.value.provider = provider
  };
  const apiSelectFunction = async () => {
    const config = getPaymentConfig.value
    if (!config) throw new Error('selectedPlan or selectedPlan not select')
    try {
      if (selectedPlan.value) await requestSubscriptionTicketForEventSourcePayLink(config);
      else if (selectedCUPack.value) await requestPackTicketForEventSourcePayLink(config)
      else console.error('selectedPlan or selectedPlan not select')
    } catch (e) {
      console.log(e);
      throw e
    }
  }
  //@ts-ignore
  const requestPackTicketForEventSourcePayLink = async (config) => {
    try {
      console.log(config);
      //@ts-ignore
      const response = await additionalServiceCreate(config)
      setTicketGetPaymentLink(response.data.ticket_get_payment_link);
      setRedirectToCheckoutProvider(response.data.payment_provider);
    } catch (e) {
      console.log(e);
      throw e
    }
  }

  //@ts-ignore
  const requestSubscriptionTicketForEventSourcePayLink = async (config) => {
    try {
      //@ts-ignore
      const response = await fetchSubscriptionCreate(config);
      setTicketGetPaymentLink(response.data.ticket_get_payment_link);
      setRedirectToCheckoutProvider(response.data.payment_provider);
    } catch (e) {
      console.log(e);
      throw e
    }
  };

  const createSseObserverPayLink = async () => {
    try {
      const response = await sseObserverPayLink(ticketGetPaymentLink.value);
      setRedirectToCheckoutLink(response)
    } catch (e) {
      console.log(e);
      throw e
    }
  };

  const setCurrentSubscriptionId = (id: string) => {
    currentSubscriptionId.value = id;
  }

  return {
    Periods,
    paymentProcessModalHistory,
    paymentProcessModalOption,
    selectedCUPack,
    selectedPeriod,
    selectedPlan,
    redirectToCheckoutOption,
    PaymentProviders,
    selectedPaymentProviders,
    ticketGetPaymentLink,
    inProcessOfReceivingPaymentLink,
    paymentProcessModalInfo,
    getPaymentConfig,
    explicitConsentModalDisplay,
    currentSubscriptionId,
    clearHistoryComponent,
    setExplicitConsentModalDisplay,
    setRedirectToCheckoutLink,
    setRedirectToCheckoutProvider,
    pushHistoryInPaymentProcessModalHistory,
    setTicketGetPaymentLink,
    requestSubscriptionTicketForEventSourcePayLink,
    createSseObserverPayLink,
    apiSelectFunction,
    setCurrentSubscriptionId
  };
});

export const usePaymentProcessStore = defineStore('paymentProcessStore', () => {
  const privateStore = usePaymentProcessPrivateStore();
  const router = useRouter();
  const packageStore = usePackageStore();

  const getPaymentProcessModalDisplay = computed(() => privateStore.paymentProcessModalOption.display);
  const getPaymentProcessModalComponent = computed(() => privateStore.paymentProcessModalOption.component);
  const getPaymentProcessModalInfo = computed(() => privateStore.paymentProcessModalInfo);
  const getPeriods = computed(() => privateStore.Periods);
  const getSelectedPeriod = computed(() => privateStore.selectedPeriod);
  const getPaymentProviders = computed(() => privateStore.PaymentProviders);
  const getSelectedPaymentProvider = computed(() => privateStore.selectedPaymentProviders);
  const getPaymentProcessModalHistory = computed(() => privateStore.paymentProcessModalHistory);
  const getSelectedPlan = computed(() => privateStore.selectedPlan);
  const getProcessOfReceivingPaymentLink = computed(() => privateStore.inProcessOfReceivingPaymentLink);
  const getSliceToCheckoutLink = computed(() => {
    if (privateStore.redirectToCheckoutOption.provider === 'nowpayments') return privateStore.redirectToCheckoutOption.paymentLink
    const startIndex = privateStore.redirectToCheckoutOption.paymentLink.lastIndexOf('/')
    return privateStore.redirectToCheckoutOption.paymentLink.slice(startIndex)
  });
  const getExplicitConsentModalDisplay = computed(() => privateStore.explicitConsentModalDisplay);
  const getSelectedCUPack = computed(() => {
    return privateStore.selectedCUPack
  })
  const setPaymentProcessModalDisplay = (display: boolean) => {
    if (display && !getPaymentProcessModalComponent.value) {
      console.error('Payment process modal hasn\'t component!');
      return;
    }
    if (display && !getSelectedPlan.value && getPaymentProcessModalComponent.value === 'SelectPaymentMethod') {
      console.error('Payment process modal hasn\'t selected plan');
      return;
    }
    privateStore.paymentProcessModalOption.display = display;
    if (!display) privateStore.clearHistoryComponent()
  };
  const setProcessOfReceivingPaymentLink = (isLoading: boolean) => {
    privateStore.inProcessOfReceivingPaymentLink = isLoading;
  };

  const setSelectedPlan = (plan: ICUPlan<StarterPlanCUType | ProPlanCUType> | null) => {
    privateStore.selectedPlan = plan;
    if (plan) setCUPack(null);
  };
  const setCUPack = (cuPack: ICUPackage<CUPackageType> | null) => {
    privateStore.selectedCUPack = cuPack;
    if (cuPack) setSelectedPlan(null);
  }
  const setPaymentProcessModalComponent = (component: PaymentProcessModalComponentTypesType) => {
    if (component === 'TopUp') {
      if (getSelectedCUPack.value && getSelectedPlan.value?._id.includes('pro')) setCUPack(packageStore.getPacks[0])
    }
    privateStore.pushHistoryInPaymentProcessModalHistory(component);
    privateStore.paymentProcessModalOption.component = component;
  };

  const setPaymentProcessModalInfo = (info: PaymentProcessModalInfoTypesType | null) => {
    privateStore.paymentProcessModalInfo = info;
  }

  const backInHistoryComponent = () => {
    privateStore.paymentProcessModalHistory.pop();
    const previousComponent = privateStore.paymentProcessModalHistory[privateStore.paymentProcessModalHistory.length -1]
    if (previousComponent) setPaymentProcessModalComponent(previousComponent)
  };
  const setSelectPeriod = (item: ISelectPeriodItem<PeriodCUTypesType>) => {
    privateStore.selectedPeriod = item;
  };
  const setPaymentProvider = (provider: ISelectedPaymentProvider<PaymentProviderTypesType>) => {
    privateStore.selectedPaymentProviders = provider;
  };
  const setPaymentModel = (paymentModel: PaymentModalTypesType) => {
    privateStore.selectedPaymentProviders.paymentModel = paymentModel;
  };
  const closeExplicitConsentModal = () => {
    privateStore.setExplicitConsentModalDisplay(false);
  };

  const redirectToCheckout = async () => {
    if (privateStore.redirectToCheckoutOption.provider === 'paddle') {
      await router.push(`/checkout${getSliceToCheckoutLink.value}`)
    }
    else privateStore.setExplicitConsentModalDisplay(true);
  }


  const requestPay = async () => {
    try {
      setProcessOfReceivingPaymentLink(true);
      privateStore.setTicketGetPaymentLink('')
      await privateStore.apiSelectFunction();
      await privateStore.createSseObserverPayLink();
      await redirectToCheckout();
      setPaymentProcessModalDisplay(false);
    } catch (e) {
      console.log(e);
    } finally {
      setProcessOfReceivingPaymentLink(false);
    }
  };

  const setCurrentSubscriptionId = (id: string) => {
    privateStore.setCurrentSubscriptionId(id);
  }

  return {
    getPeriods,
    getSelectedPlan,
    getPaymentProcessModalDisplay,
    getSelectedPeriod,
    getPaymentProviders,
    getSelectedPaymentProvider,
    getPaymentProcessModalComponent,
    getPaymentProcessModalHistory,
    getProcessOfReceivingPaymentLink,
    getSliceToCheckoutLink,
    getExplicitConsentModalDisplay,
    getSelectedCUPack,
    getPaymentProcessModalInfo,
    closeExplicitConsentModal,
    backInHistoryComponent,
    setPaymentModel,
    setPaymentProcessModalDisplay,
    setSelectPeriod,
    setPaymentProvider,
    setPaymentProcessModalComponent,
    setSelectedPlan,
    setProcessOfReceivingPaymentLink,
    setPaymentProcessModalInfo,
    setCUPack,
    requestPay,
    setCurrentSubscriptionId
  };
});
