import { SharedApiModule } from '@/shared'
import store from '@/store';
import { AxiosResponse } from 'axios';
import {
  IAccessTokenDedicated,
  IAccessTokenShared, IRequestForAddDedicatedAccessToken,
  IRequestForAddSharedAccessToken, IRequestForRenameAccessToken,
} from '@/entities/accessToken';

// TODO: rename fetchEndpoints (fetchAccessTokens)
const fetchEndpoints = () => SharedApiModule.HttpClients.httpClientV3.get('access_tokens/', {
  headers: {
    Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
  },
}) as Promise<AxiosResponse<(IAccessTokenShared | IAccessTokenDedicated)[]>>;

const fetchDeleteAccessToken = (accessTokenKey: string, recaptchaToken: string) => SharedApiModule.HttpClients.httpClientV3.delete(`access_tokens/${accessTokenKey}/`, {
  headers: {
    'recaptcha-token': recaptchaToken,
    Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
  },
});
const fetchRollAccessToken = (accessTokenKey: string, recaptchaToken: string) => SharedApiModule.HttpClients.httpClientV3.put(`access_tokens/${accessTokenKey}/`, {}, {
  headers: {
    'recaptcha-token': recaptchaToken,
    Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
  },
}) as Promise<AxiosResponse<(IAccessTokenShared | IAccessTokenDedicated)>>;
const fetchAddNewAccessToken = (parameters: (IRequestForAddDedicatedAccessToken | IRequestForAddSharedAccessToken), recaptchaToken: string) => SharedApiModule.HttpClients.httpClientV3.post('access_tokens/', {
    ...parameters,
  },
  {
    headers: {
      'recaptcha-token': recaptchaToken,
      Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
    },
  },
) as Promise<AxiosResponse<IAccessTokenDedicated | IAccessTokenShared>>;
const fetchRenameAccessToken = (parameters: IRequestForRenameAccessToken, recaptchaToken: string) => SharedApiModule.HttpClients.httpClientV3.put('access_tokens/', {
  ...parameters,
}, {
  headers: {
    'recaptcha-token': recaptchaToken,
    Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
  },
});


export default {
  fetchEndpoints,
  fetchDeleteAccessToken,
  fetchRollAccessToken,
  fetchAddNewAccessToken,
  fetchRenameAccessToken
};