import axios from 'axios';
import store from '@/store';

const AXIOS_CONFIG = {
  baseURL: process.env.VUE_APP_STATS_API,
  headers: {
    'Content-Type': 'application/json',
  },
};

const httpClient = axios.create(AXIOS_CONFIG);

httpClient.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`;
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
);

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const config = error.config;
    console.error(`[${config.method.toUpperCase()}] ${config.url} error: ${error}`);
    return Promise.reject(error);
  },
);

const getStatisticsByParameters = (protocols: string[], networks: string[], apis: string[], addons: string[], duration: string, namespace: string, recaptchaToken: string) => {
  // @ts-ignore
  const mappedAddons = Array.from(new Set(addons.map((addon) => addon.split(`(${addon.match(/\((.*?)\)/)[1]})`)[0])));
  // @ts-ignore
  const mappedApis = Array.from(new Set([...apis, ...addons.map((addon) => addon.match(/\((.*?)\)/)[1])]));
  return httpClient.post('', {
      namespaces: [namespace],
      protocols,
      networks,
      apis: mappedApis,
      addons: mappedAddons,
      duration,
    }, {
      headers: {
        'recaptcha-token': recaptchaToken,

      },
    },
  );
};

const getStatisticsByTokens = (accessTokens: string[], duration: string, namespace: string, recaptchaToken: string) =>
  httpClient.post('', {
      duration,
      access_tokens: accessTokens,
      namespaces: [namespace],
    }, {
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    },
  );

const getStatisticsPreviewByTokens = (accessTokens: string[], namespace: 'shared' | 'dedicated' = 'shared', recaptchaToken: string) =>
  httpClient.post(`tokens/`, { access_tokens: accessTokens, namespaces: [namespace] }, {
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    },
  );

export {
  getStatisticsByParameters,
  getStatisticsPreviewByTokens,
  getStatisticsByTokens,
};
