import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col" }
const _hoisted_2 = { class: "flex items-center mb-8" }
const _hoisted_3 = { class: "pl-[36px] ml-auto text-sm gb-text-text-main leading-5 font-bold" }
const _hoisted_4 = { class: "font-bold text-[18px] leading-6 text-center mb-2" }
const _hoisted_5 = { class: "font-medium text-sm text-center" }

import { GetBlockUIButton, GetBlockUIIcon } from '@getblockui/core';
import ModalTemplate from '@/components/modals/ModalTemplate.vue';

import { useI18n } from 'vue-i18n';
import { useGetCurrentPlan } from '@/features/currentPlan/useGetCurrentPlan';


export default /*@__PURE__*/_defineComponent({
  __name: 'DowngradeModal',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const emits = __emit;
const { t } = useI18n();

const { getCurrentPlan } = useGetCurrentPlan();

const getDateFromSQLDate = (sqlDate: string) => {
  if (sqlDate) {
    const dateParts = sqlDate.split('-');
    return new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2].substring(0, 2));
  }

  return new Date();
};

const formatDate = (
  sqlDate: string,
  yearFormat: 'numeric' | '2-digit' = '2-digit',
  monthFormat: 'short' | 'numeric' | '2-digit' | 'long' | 'narrow' = 'short',
) => {
  const date = getDateFromSQLDate(sqlDate);

  return date.toLocaleString('en-US', {
    year: yearFormat,
    month: monthFormat,
    day: 'numeric',
  });
};

const close = () => {
  emits('close');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalTemplate, {
    "additional-classes": "max-w-[400px] w-[400px]",
    paddings: "p-6",
    rounded: "rounded-24",
    onClose: close
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("header", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)(`downgrade-modal.title`)), 1),
          _createVNode(_unref(GetBlockUIButton), {
            class: "ml-auto",
            "icon-button": "",
            "size-type": "small",
            "color-type": "secondary",
            onClick: close
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(GetBlockUIIcon), { "icon-name": "x-close" })
            ]),
            _: 1
          })
        ]),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)(`downgrade-modal.subtitle.1`)) + _toDisplayString(_unref(getCurrentPlan).name) + _toDisplayString(_unref(t)(`downgrade-modal.subtitle.2`)) + _toDisplayString(formatDate(_unref(getCurrentPlan).expired)), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)(`downgrade-modal.text`)), 1),
        _createVNode(_unref(GetBlockUIButton), {
          class: "justify-center mt-8",
          onClick: close
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Ok")
          ])),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}
}

})