import { SharedApiModule } from '@/shared'
import store from '@/store';
import { AxiosResponse } from 'axios';
import { ICUPlan, IEnterpriseRequestForm, ISharedPlans } from '@/entities/sharedPlans/types';
import {
  CUPlansIdType,
  ProPlanCUType,
  StarterPlanCUType,
  StartPlanProductId,
  UnlimitedPlanProductId,
} from '@/shared/products/types';

const fetchPlans = () => SharedApiModule.HttpClients.httpClientV3.get('/prices/plans/', {
  headers: {
    Authorization: `Bearer ${store.state.accessToken}`,
  },
}) as Promise<AxiosResponse<{[key in StartPlanProductId | UnlimitedPlanProductId | CUPlansIdType<StarterPlanCUType> | CUPlansIdType<ProPlanCUType>]: ISharedPlans<StartPlanProductId | UnlimitedPlanProductId> | ICUPlan<StarterPlanCUType> | ICUPlan<ProPlanCUType>}>>

const sendEnterpriseRequest = (data: IEnterpriseRequestForm, recaptchaToken: string) =>
  SharedApiModule.HttpClients.httpClientV3.post('enterprise-request', data, {
    headers: {
      'recaptcha-token': recaptchaToken
    }
  });

export { fetchPlans, sendEnterpriseRequest }
