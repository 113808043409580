export const en = {
  "notification": {
    "error": {
      "authentication": {
        "register": "Failed to register",
        "login": "Failed to login",
        "reset-password": "Failed to request password reset",
        "recover-password": "Failed to request password recovery",
        "set-password": "Failed to set a new password",
        "sign-in": "Wrong email or password"
      },
      "webhooks": {
        "get-public-key": "Failed to get a public key",
        "generate-public-key": "Failed to generate a new public key"
      },
      "general": {
        "get-pricing": "Failed to get pricing",
        "get-increase-pricing": "Failed to get data for increase requests",
        "get-list-of-nodes": "Failed to get a list of nodes",
        "create-order": "Failed to create an order",
        "get-statistics": "Failed to get statistics",
        "send-message": "Failed to send a message"
      },
      "dedicated-order": {
        "calculate-price": "Failed to calculate the price",
        "get-dedicated-nodes": "Failed to get your dedicated nodes",
        "create-order": "Failed to create an order"
      },
      "projects": {
        "get-list-of-projects": "Failed to get the projects list",
        "hide-endpoint": "Failed to hide the endpoint",
        "get-statistics": "Failed get statistics"
      },
      "endpoints": {
        "get-endpoints": "Failed to get endpoints",
        "create-endpoint": "Failed to create endpoint",
        "delete-endpoint": "Failed to delete endpoint",
        "roll-endpoint": "Failed to roll endpoint",
        "rename-endpoint": "Failed to rename endpoint"
      },
      "user": {
        "get-payment-history": "Failed to get payment history",
        "get-account-information": "Failed to get account information",
        "get-requests-balance": "Failed to get requests balance",
        "change-account-information": "Failed to change account information",
        "get-checkout-history": "Failed to get payout history",
        "get-referral-info": "Failed to get affiliate program info",
        "accept-referral-program": "Failed to join referral program",
        "send-payout-code": "Failed to send code",
        "create-payout": "Failed to create payout",
        "get-notifications": "Failed to get notifications",
        "change-notifications-trigger": "Failed to configure notification settings",
        "enable-notifications-email-destination": "Failed to enable email notifications",
        "enable-notifications-telegram-destination": "Failed to enable telegram notifications",
        "enable-notifications-webhook-destination": "Failed to enable webhook notifications",
        "disable-notifications-email-destination": "Failed to disable email notifications",
        "disable-notifications-telegram-destination": "Failed to disable telegram notifications",
        "disable-notifications-webhook-destination": "Failed to disable webhook notifications",
        "change-notifications-webhook-url": {
          "title": "Unable to change URL",
          "description": "URL should respond with status code 200."
        }
      },
      "payment": {
        "payment": {
          "title": "Payment failed",
          "description": "Try later"
        },
        "forbidden-country": {
          "title": "The payment method is not supported in your country"
        }
      },
      "teams": {
        "create-new-team": "Failed to create a team",
        "get-team-list": "Failed to get the list of teams",
        "get-current-team": "Failed to get team information",
        "get-invite-list": "Failed to get the list of invites",
        "respond-invite": "Failed to perform an action on the invitation",
        "rename-team": "Failed to rename a team",
        "remove-member": "Failed to remove member",
        "add-member": "Failed to send invitation",
        "rename-member": "Failed to rename a member",
        "set-access-level": "Failed to change an access level",
        "team-auth": "Team account authorization error",
        "info": "Failed to get information"
      },
      "error-description": "Something went wrong at our end. Please try again later"
    },
    "success": {
      "authentication": {
        "register": {
          "title": "Check your inbox!",
          "description": "We've sent you a welcome email"
        },
        "forgot-password": {
          "title": "Check your inbox!",
          "description": "Password reset link has been sent to"
        }
      },
      "general": {
        "send-feedback": {
          "title": "Your request successfully sent!",
          "description": "Thank you! Your request successfully sent."
        }
      },
      "dedicated-order": {
        "create-project": {
          "title": "Project has been created",
          "description": "It will appear in the project list"
        }
      },
      "payment": {
        "completed": {
          "title": "Payment completed successfully",
          "description": "You have successfully purchased the package"
        }
      },
      "project": {
        "hide-endpoint": {
          "title": "Endpoint-access token pair is hidden"
        },
        "copy-endpoint": {
          "title": "Endpoint is copied"
        },
        "copy-token": {
          "title": "Token is copied"
        }
      },
      "access-token": {
        rename: {
          title: 'The access token\'s name has been successfully changed'
        }
      }
    },
    "attention": {
      "authentication": {
        "metamask": {
          "title": "You don’t have Metamask installed.",
          "description": "Please download the  <a href='https://metamask.io/' target='_blank' class='text-text-action font-medium leading-6'>Metamask</a> extension to continue."
        }
      },
      "teams": {
        "no-access-uid": {
          "title": "You have been removed from the team",
          "description": "Please reach out to the team administrator for further details"
        }
      }
    }
  }
}