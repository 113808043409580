import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import {
  IAccessTokenDedicated,
  IAccessTokenShared,
  IRequestForAddDedicatedAccessToken, IRequestForAddSharedAccessToken, IRequestForRenameAccessToken, ISharedNode,
} from '@/entities/accessToken';
import { AccessTokenApi } from '@/entities/accessToken';
import { DedicatedType, SharedType } from '@/shared/service/types';
import { AppNotificationModule, AppTurnstileCFCaptchaModule } from '@/application';

const useAccessTokenPrivateStore = defineStore('accessTokenPrivate', () => {
  const sharedAccessTokens = ref<IAccessTokenShared[]>([]);
  const dedicatedAccessTokens = ref<IAccessTokenDedicated[]>([]);

  const setSharedAccessTokens = (accessTokens: IAccessTokenShared[]) => {
    sharedAccessTokens.value = accessTokens;
  };
  const setDedicatedAccessTokens = (accessTokens: IAccessTokenDedicated[]) => {
    dedicatedAccessTokens.value = accessTokens;
  };
  const setDedicatedAccessToken = (accessToken: IAccessTokenDedicated) => {
    dedicatedAccessTokens.value.push(accessToken);
  };
  const setSharedAccessToken = (accessToken: IAccessTokenShared) => {
    sharedAccessTokens.value.push(accessToken);
  };

  const changeAccessTokenKey = (namespace: (SharedType | DedicatedType), accessTokenOption: {
    id: `access_tokens/${string}`,
    newKey: string
  }) => {
    const accessTokens = namespace === 'shared' ? sharedAccessTokens.value : dedicatedAccessTokens.value;
    for (let i = 0; i <= accessTokens.length - 1; i++) {
      if (accessTokens[i]._id === accessTokenOption.id) {
        accessTokens[i]._key = accessTokenOption.newKey;
        break;
      }
    }
  };
  const renameAccessToken = (namespace: (SharedType | DedicatedType), key: string, name: string) => {
    const accessTokens = namespace === 'shared' ? sharedAccessTokens.value : dedicatedAccessTokens.value;
    for (let i = 0; i <= accessTokens.length - 1; i++) {
      if (accessTokens[i]._id === `access_tokens/${key}`) {
        accessTokens[i].name = name;
        break;
      }
    }
  }

  return {
    sharedAccessTokens,
    dedicatedAccessTokens,
    renameAccessToken,
    setSharedAccessTokens,
    setDedicatedAccessTokens,
    setDedicatedAccessToken,
    setSharedAccessToken,
    changeAccessTokenKey,
  };

});

export const useAccessTokenStore = defineStore('accessToken', () => {
  const privateStore = useAccessTokenPrivateStore();
  const notificationStore = AppNotificationModule.useNotificationStore();
  const { executeTurnstile } = AppTurnstileCFCaptchaModule.useTurnstile()

  const currentSharedNode = ref<ISharedNode | null>(null);
  const setCurrentSharedNode = (node: string) => {
    currentSharedNode.value = {
      configuration: {
        protocol: node
      }
    }
  }

  const getSharedAccessTokens = computed(() => privateStore.sharedAccessTokens);
  const getDedicatedAccessToken = computed(() => privateStore.dedicatedAccessTokens);

  const getSharedNodes = computed(() => {
    const nodes: Set<string> = new Set
    getSharedAccessTokens.value.forEach((accessTokens) => nodes.add(accessTokens.protocol))
    return nodes
  });
  const getSharedAccessTokensFromProtocol = computed(() => (protocol: string) => getSharedAccessTokens.value.filter((accessToken) => accessToken.protocol === protocol))

  const getDedicatedAccessTokenKeys = computed(() => getDedicatedAccessToken.value.map((accessToken) => accessToken._key));
  const getAccessTokensFromDedicatedNode = computed(() => (dedicatedNodeId: `dedicated_node_service/${string}`) => getDedicatedAccessToken.value.filter((dedicatedAccessToken) => dedicatedAccessToken.dn_id === dedicatedNodeId));

  const getSharedAccessTokenKeys = computed(() => getSharedAccessTokens.value.map((accessToken) => accessToken._key));

  const deleteSharedAccessToken = (id: string) => {
    const sharedAccessTokens = getSharedAccessTokens.value.filter((accessTokens) => accessTokens._key !== id)
    privateStore.setSharedAccessTokens(sharedAccessTokens)
  }
  const deleteDedicatedAccessToken = (id: string) => {
    const dedicatedAccessTokens = getDedicatedAccessToken.value.filter((accessTokens) => accessTokens._key !== id);
    privateStore.setDedicatedAccessTokens(dedicatedAccessTokens)
  }

  const requestAccessTokens = async () => {
    try {
      const endpoints = await AccessTokenApi.fetchEndpoints();
      const sharedAccessTokens = endpoints.data.filter((endpoint) => endpoint.namespace === 'shared');
      const dedicatedAccessTokens = endpoints.data.filter((endpoint) => endpoint.namespace === 'dedicated');
      privateStore.setSharedAccessTokens(sharedAccessTokens as IAccessTokenShared[]);
      privateStore.setDedicatedAccessTokens(dedicatedAccessTokens as IAccessTokenDedicated[]);
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      notificationStore.setNotificationMessage({ ...errorData('endpoints_get-endpoints'), type: 'error' })
      notificationStore.setNotificationMessageDisplay(true);
    }
  };
  const requestForDeleteAccessToken = async (accessTokenId: string, namespace: SharedType | DedicatedType) => {
    try {
      const turnstileToken = await executeTurnstile(`delete-${namespace}-access-token`)
      await AccessTokenApi.fetchDeleteAccessToken(accessTokenId, turnstileToken as string);
      if (namespace === 'shared') deleteSharedAccessToken(accessTokenId)
      else deleteDedicatedAccessToken(accessTokenId)
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      notificationStore.setNotificationMessage({ ...errorData('endpoints_delete-endpoint'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true)
    }
  };
  const requestForRollDedicatedAccessToken = async (accessTokenId:  `access_tokens/${string}`, accessTokenKey: string, namespace: SharedType | DedicatedType) => {
    try {
      const turnstileToken = await executeTurnstile(`roll-${namespace}-access-token`)
      const response = await AccessTokenApi.fetchRollAccessToken(accessTokenKey, turnstileToken as string);
      privateStore.changeAccessTokenKey(namespace, { id: accessTokenId, newKey: response.data._key });
      privateStore.renameAccessToken(namespace, accessTokenId, response.data.name);
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      notificationStore.setNotificationMessage({ ...errorData('endpoints_roll-endpoint'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true)
    }
  };
  const requestForAddDedicatedAccessToken = async (parameters: IRequestForAddDedicatedAccessToken) => {
    try {
      const turnstileToken = await executeTurnstile(`add-dedicated-access-token`)
      const response = await AccessTokenApi.fetchAddNewAccessToken(parameters, turnstileToken as string);
      privateStore.setDedicatedAccessToken(response.data as IAccessTokenDedicated);
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      notificationStore.setNotificationMessage({ ...errorData('endpoints_create-endpoint'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true)
    }
  };
  const requestForAddSharedAccessToken = async (parameters: IRequestForAddSharedAccessToken) => {
    try {
      const turnstileToken = await executeTurnstile(`add-shared-access-token`);
      const response = await AccessTokenApi.fetchAddNewAccessToken(parameters, turnstileToken as string);
      privateStore.setSharedAccessToken(response.data as IAccessTokenShared);
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      notificationStore.setNotificationMessage({ ...errorData('endpoints_create-endpoint'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true)
    }
  };
  const requestForRenameAccessToken = async (parameters: IRequestForRenameAccessToken, namespace: SharedType | DedicatedType) => {
    try {
      const turnstileToken = await executeTurnstile(`rename-${namespace}-token`);
      await AccessTokenApi.fetchRenameAccessToken(parameters, turnstileToken as string)
      privateStore.renameAccessToken(namespace, parameters.id, parameters.name);
      const { successData } = AppNotificationModule.useNotification();
      notificationStore.setNotificationMessage({ ...successData('access-token_rename'), type: 'high' });
      notificationStore.setNotificationMessageDisplay(true)
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      notificationStore.setNotificationMessage({ ...errorData('endpoints_rename-endpoint'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true)
    }
  }

  return {
    currentSharedNode,
    setCurrentSharedNode,
    getSharedAccessTokens,
    getDedicatedAccessToken,
    getAccessTokensFromDedicatedNode,
    getDedicatedAccessTokenKeys,
    getSharedAccessTokenKeys,
    getSharedNodes,
    getSharedAccessTokensFromProtocol,
    deleteSharedAccessToken,
    deleteDedicatedAccessToken,
    requestAccessTokens,
    requestForDeleteAccessToken,
    requestForRollDedicatedAccessToken,
    requestForAddDedicatedAccessToken,
    requestForAddSharedAccessToken,
    requestForRenameAccessToken,
  };
});