export default {
  "notifying-banner": {
    'before-price-change': {
      'title': "New Pricing Coming Soon!",
      'subscription': {
        1: 'Dedicated node prices are increasing on',
        2: 'Lock in your current rate before the change!'
      },
      'button': 'Configure Node'
    },
    'after-price-change': {
      'title': "Dedicated Node Pricing Update!",
      'subscription': {
        1: 'Our new pricing is now live.',
        2: ' Explore the details in our latest blog post.'
      },
      'button': 'Learn more'
    }
  }
}