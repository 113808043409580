import { onUnmounted, ref } from 'vue';

export function useTurnstile() {
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 1000;

  const turnstileOnload = ref<boolean>(false);
  const SITE_KEY = process.env.VUE_APP_TURNSTILE_CAPTCHA_KEY as string;
  const widgetId = ref<string | null | undefined>(null);


  const loadTurnstile = (retryCount = 0) => new Promise((resolve, reject) => {
    if (window.turnstile) {
      turnstileOnload.value;
      window.dispatchEvent(new Event('turnstileScriptLoaded'))
      resolve(true);
    } else {
      const script = document.createElement('script');
      script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit&onload=onloadTurnstileCallback';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        console.log('Turnstile CF Load');
        window.dispatchEvent(new Event('turnstileScriptLoaded'))
        turnstileOnload.value = true;
        resolve(true);
      };
      script.onerror = (error) => {
        console.error(`Turnstile CF Error load (attempt ${retryCount + 1}): ${error}`);
        turnstileOnload.value = false;
        if (retryCount < MAX_RETRIES) {
          setTimeout(() => {
            console.log(`Retrying Turnstile load... Attempt ${retryCount + 2}`);
            loadTurnstile(retryCount + 1).then(resolve).catch(reject);
          }, RETRY_DELAY);
        } else {
          reject(new Error('Failed to load Turnstile script after multiple attempts'));
        }
      };
      document.head.appendChild(script);
    }
  });

  const deleteWidget = () => {
    if (window.turnstile && widgetId.value) window.turnstile.remove(widgetId.value);
  };

  const getTurnstile = (action: string) => {
    return new Promise((resolve, reject) => {
      const container = document.getElementById('turnstile-widget');
      if (!container) reject();
      if (!window.turnstile) reject();
      if (widgetId.value) deleteWidget();
      widgetId.value = window?.turnstile?.render(container as HTMLElement, {
        sitekey: SITE_KEY,
        callback: (token) => resolve(token as string),
        'error-callback': (error) => {
          console.log(`Turnstile CF Error: ${error}`);
          reject();
        },
        'expired-callback': () => {
          console.log(`Turnstile CF Expire`);
          reject();
        },
        'refresh-expired': 'never',
        retry: 'never',
        action: action,
        theme: 'light',
        execution: 'render'
      });
    });
  }

  type Length<T extends string, A extends readonly number[] = []> =
    T extends `${infer _}${infer Rest}`
      ? A['length'] extends 32
        ? never
        : Length<Rest, readonly [...A, 1]>
      : A['length'];

  const executeTurnstile = <T extends string>(action: Length<T> extends never ? never : T) => {
    return new Promise((resolve, reject) => {
      const handler = () => {
        getTurnstile(action)
          .then((token) => {
            resolve(token)
          })
          .catch((error) => console.error('Turnstile error:', error));

        window.removeEventListener('turnstileScriptLoaded', handler);
      };

      if (!window.turnstile) window.addEventListener('turnstileScriptLoaded', handler);
      else handler()
    })
  };

  onUnmounted(() => {
    deleteWidget();
  });

  return { executeTurnstile, loadTurnstile };
}