import { BalancesActions, BalancesGetters, BalancesMutations, BalancesState } from '@/types/balances/store';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/store';
import { AppNotificationModule } from '@/application'
import { BalancesBuilderImplementation } from '@/entities/balances/classes';
import { IBalances } from '@/entities/balances/types';
import { fetchBalance } from '@/entities/balances/api';

export type State = BalancesState;
export type Mutations = BalancesMutations;
export type Getters = BalancesGetters;
export type Actions = BalancesActions;

const state: () => State = () => ({
  balances: new BalancesBuilderImplementation().build()
})

const getters: GetterTree<State, RootState> & Getters = {};

const mutations: MutationTree<State> & Mutations = {
  setBalances(state: State, payload: IBalances) {
    state.balances = payload
  }
}

const actions: ActionTree<State, RootState> & Actions = {
  async getBalances({commit}) {
    try {
      const res = await fetchBalance()
      const balance = new BalancesBuilderImplementation()
        .setFree(res.data.free)
        .setMain(res.data.main)
        .setLocked(res.data.locked)
      commit('setBalances', balance.build())
    } catch (e) {
      const { errorData } = AppNotificationModule.useNotification();
      const notificationStore = AppNotificationModule.useNotificationStore();
      notificationStore.setNotificationMessage(  { ...errorData('general_get-pricing'), type: 'error' });
      notificationStore.setNotificationMessageDisplay(true);
    }
  }
}

const balances: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
};

export default balances;