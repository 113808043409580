import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "font-bold text-2xl leading-[30px] gb-text-text-main" }
const _hoisted_3 = {
  key: 0,
  class: "text-[12px] font-bold leading-[14px] text-text-tint-blue bg-[#EBF3FF] border border-[#C2DAFF] py-1 px-1.5 rounded-16"
}
const _hoisted_4 = {
  key: 0,
  class: "flex gap-1 items-baseline"
}
const _hoisted_5 = { class: "font-bold text-[40px] leading-[48px] gb-text-text-main" }
const _hoisted_6 = { class: "font-medium text-sm gb-text-text-tertiary" }
const _hoisted_7 = {
  key: 1,
  class: "flex flex-col items-start"
}
const _hoisted_8 = { class: "font-bold text-[40px] leading-[48px] gb-text-text-main" }

import { computed, PropType } from 'vue';
import {
  CUPlansIdType,
  DedicatedPlanType,
  EnterprisePlanCUType,
  ProPlanCUType,
  StarterPlanCUType,
} from '@/shared/products/types';
import { useI18n } from 'vue-i18n';
import { Period12MType, Period1MType } from '@/shared/service/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'PricingCardHeader',
  props: {
  price: {
    type: Number,
    default: 0
  },
  name: {
    type: String,
    default: ''
  },
  planId: {
    type: String as PropType<CUPlansIdType<StarterPlanCUType | ProPlanCUType | EnterprisePlanCUType | DedicatedPlanType>>,
    default: ''
  },
  basePrice: {
    type: Number,
    default: 0
  },
  period: {
    type: String as PropType<Period1MType | Period12MType>,
    default: '1m'
  }
},
  setup(__props) {

const { t } = useI18n()

const props = __props

const pricePlan = computed(() => props.price)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-col justify-between h-[94px]", { "!h-[114px]" : __props.planId === "plans/dedicated", "!h-[106px]" : __props.planId === "plans/enterprise_plan" }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(props.name), 1),
      (__props.planId === "plans/pro")
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(t)('pricing-card.most-popular')), 1))
        : _createCommentVNode("", true)
    ]),
    (__props.planId === "plans/pro" || __props.planId === "plans/starter" || __props.planId === "plans/free")
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "font-medium text-2xl leading-10 gb-text-text-tertiary" }, "$", -1)),
          _createElementVNode("span", _hoisted_5, _toDisplayString(__props.period === '1m' ? pricePlan.value : Math.floor(pricePlan.value / 12)), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('pricing-card.header.per-month')), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("p", {
            class: _normalizeClass(["text-[14px] text-[#8E8E93] leading-5", { "!text-[#828999]": __props.planId === "plans/dedicated" }])
          }, _toDisplayString(_unref(t)('pricing-card.starting-at')), 3),
          _createElementVNode("div", null, [
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "font-medium text-2xl leading-10 gb-text-text-tertiary" }, "$", -1)),
            _createElementVNode("span", _hoisted_8, _toDisplayString(__props.basePrice), 1),
            _createElementVNode("span", {
              class: _normalizeClass(["font-medium text-sm gb-text-text-tertiary", { "!text-[#828999]": __props.planId === "plans/dedicated" }])
            }, _toDisplayString(_ctx.$t('pricing-card.header.per-month')), 3)
          ])
        ]))
  ], 2))
}
}

})