<script lang="ts" setup>
import PricingCardHeader from './parts/PricingCardHeader.vue';
import PricingCardParametrs from './parts/PricingCardParametrs.vue';
import PricingCardOptions from './parts/PricingCardOptions.vue';
import { GetBlockUIButton } from '@getblockui/core';
import { PropType, defineProps, defineEmits, computed, ref } from 'vue';
import { ICUPlan } from '@/entities/sharedPlans/types';
import { DedicatedPlanType, EnterprisePlanCUType, ProPlanCUType, StarterPlanCUType } from '@/shared/products/types';
import { Period12MType, Period1MType } from '@/shared/service/types';
import { useGetCurrentPlan } from '@/features/currentPlan/useGetCurrentPlan';
import DowngradeModal from '@/components/modals/DowngradeModal.vue';
import { useRoute } from 'vue-router';

const emit = defineEmits(['onChoosePlan', 'freePlan']);
const route = useRoute();

const props = defineProps({
  plan: {
    type: Object as PropType<ICUPlan<StarterPlanCUType | ProPlanCUType | EnterprisePlanCUType | DedicatedPlanType>>,
    default: () => ({}),
  },
  period: {
    type: String as PropType<Period1MType | Period12MType>,
    default: '1m',
  },
});

const getPlanId = computed(() => props.plan._id.split('/')[1]);

const { getCurrentPlan } = useGetCurrentPlan();

const handleClickButtonChoosePlan = () => {

  if (isOnboarding.value && getCurrentPlan.value.type === props.plan?._key && props.plan._key === 'free') {
    emit('freePlan');
    return;
  }

  if (getCurrentPlan.value.type === props.plan?._key) return;
  if (
    getCurrentPlan.value.type === 'enterprise_plan' &&
    (props.plan?._key === 'starter' || props.plan?._key === 'pro')
  ) {
    showDowngradeModal.value = true;
    return;
  }
  if (getCurrentPlan.value.type === 'pro' && props.plan?._key === 'starter') {
    showDowngradeModal.value = true;
    return;
  }
  emit('onChoosePlan', props.plan);
};

const buttonColors = {
  starter: 'tint',
  pro: 'accent',
  enterprise: 'primary',
  dedicated: 'white',
  free: 'outline',
};

const showDowngradeModal = ref(false);

const isOnboarding = computed(() => route.fullPath === '/onboarding');
</script>

<template>
  <section
    class="pt-6 px-6 pb-8 flex flex-col gap-6 border gb-border-border-hover rounded-24 gb-bg-bg-base min-w-[300px] md:flex-grow sm:flex-grow xs:flex-grow"
    :class="{
      '!border-2 !border-[#2772F5]': props.plan?._id === 'plans/pro',
      dedicated: props.plan?._id === 'plans/dedicated',
    }"
  >
    <div class="flex flex-col gap-6" :class="{ '!gap-3': plan._id === 'plans/enterprise_plan' }">
      <PricingCardHeader
        :plan-id="props.plan._id"
        :name="props.plan.name"
        :price="props.plan.periods_with_prices[props.period].first_payment.discount_price"
        :base-price="props.plan?.price"
        :period="period"
      />
      <PricingCardParametrs
        :plan-id="props.plan._id"
        :price="props.plan.periods_with_prices[props.period].first_payment.discount_price"
        :access-tokens="props.plan.max_token_amount"
        :cu-amount="props.plan.cu_amount"
        :rps="props.plan.rps"
        :base-price="props.plan?.price"
      />
    </div>
    <GetBlockUIButton
      @click="handleClickButtonChoosePlan"
      :color-type="buttonColors[props.plan?.name.toLocaleLowerCase()]"
    >
      <span class="font-bold leading-20 m-auto" :class="{ '!text-text-main': plan._id === 'plans/dedicated' }">
        {{
          getCurrentPlan.name === plan.name && plan._id !== 'plans/dedicated'
            ? ( (isOnboarding && plan._id === 'plans/free') ? $t(`pricing-card.continue`) : $t(`pricing-card.your-current-plan`))
            : $t(`pricing-card.plans.${getPlanId}.button`)
        }}
      </span>
    </GetBlockUIButton>
    <div class="w-full h-0.5 gb-bg-border-hover" :class="{ 'bg-[#494E58]': plan._id === 'plans/dedicated' }"></div>
    <PricingCardOptions :type="props.plan._key" />
  </section>
  <DowngradeModal v-show="showDowngradeModal" @close="showDowngradeModal = false" />
</template>

<style scoped>
.dedicated {
  background-image: url('@/assets/images/pricing/dedicated_card_bg.svg') !important;
  background-size: cover;
  border: none;
}

.dedicated :deep(p) {
  color: white;
}

.dedicated :deep(span) {
  color: white;
}
</style>
