export default {
  'pricing-card': {
    header: {
      'per-month': '/ 每月',
    },
    parameters: {
      'cu-month': 'CU / 月',
      '1m-cu': '/ 1M CU',
      'rps': 'RPS',
      'access-token': '访问令牌',
      'unlimited': '无限',
      'up-to': '到',
      custom: '习俗',
    },
    plans: {
      free: {
        'button': '开始使用',
        'pricing-card-options': {
          'supported-chains': '所有支撑的连锁店',
          'dedicated-nodes': '专用节点',
          'support': '24/7 支持',
          'team-account': '团队帐户',
          'dedicated-clusters': '专用集群',
          'statistics': '完整的统计数据',
          'custom-rollups': '自定义应用程序',
        },
        starter: {
          'button': '开始使用',
          'pricing-card-options': {
            'supported-chains': '所有支撑的连锁店',
            'dedicated-nodes': '专用节点',
            'support': '24/7 支持',
            'team-account': '团队帐户',
            'dedicated-clusters': '专用集群',
            'statistics': '完整的统计数据',
            'advanced-statistics': '高级统计',
            'custom-rollups': '自定义应用程序',
          },
        },
        pro: {
          'button': '开始使用',
          'pricing-card-options': {
            'supported-chains': '所有支撑的连锁店',
            'dedicated-nodes': '专用节点',
            'support': '优先票支持',
            'team-account': '团队帐户',
            'dedicated-clusters': '专用集群',
            'statistics': '完整的统计数据',
            'advanced-statistics': '高级统计',
            'custom-rollups': '自定义应用程序',
          },
        },
        enterprise_plan: {
          'button': '定制',
          'pricing-card-options': {
            'supported-chains': '所有支撑的连锁店',
            'dedicated-nodes': '专用节点',
            'support': '增强的支持SLA',
            'team-account': '团队帐户',
            'dedicated-clusters': '专用集群',
            'statistics': '完整的统计数据',
            'advanced-statistics': '高级统计',
            'custom-rollups': '自定义应用程序',
          },
        },
        dedicated: {
          'button': '动工',
          'pricing-card-options': {
            'supported-chains': '所有支撑的连锁店',
            'dedicated-nodes': '存档数据',
            'support': '增强的支持SLA',
            'team-account': '团队帐户',
            'dedicated-clusters': '专用集群',
            'statistics': '完整的统计数据',
            'custom-rollups': '自定义应用程序',
          },
        },
      },
      'most-popular': '最受欢迎',
      'starting-at': '从',
      "continue": "继续",
      "your-current-plan": "您当前的计划",
    },
  },
};
