import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import PricingCardHeader from './parts/PricingCardHeader.vue';
import PricingCardParametrs from './parts/PricingCardParametrs.vue';
import PricingCardOptions from './parts/PricingCardOptions.vue';
import { GetBlockUIButton } from '@getblockui/core';
import { PropType, computed, ref } from 'vue';
import { ICUPlan } from '@/entities/sharedPlans/types';
import { DedicatedPlanType, EnterprisePlanCUType, ProPlanCUType, StarterPlanCUType } from '@/shared/products/types';
import { Period12MType, Period1MType } from '@/shared/service/types';
import { useGetCurrentPlan } from '@/features/currentPlan/useGetCurrentPlan';
import DowngradeModal from '@/components/modals/DowngradeModal.vue';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'PricingCard',
  props: {
  plan: {
    type: Object as PropType<ICUPlan<StarterPlanCUType | ProPlanCUType | EnterprisePlanCUType | DedicatedPlanType>>,
    default: () => ({}),
  },
  period: {
    type: String as PropType<Period1MType | Period12MType>,
    default: '1m',
  },
},
  emits: ['onChoosePlan', 'freePlan'],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const route = useRoute();

const props = __props;

const getPlanId = computed(() => props.plan._id.split('/')[1]);

const { getCurrentPlan } = useGetCurrentPlan();

const handleClickButtonChoosePlan = () => {

  if (isOnboarding.value && getCurrentPlan.value.type === props.plan?._key && props.plan._key === 'free') {
    emit('freePlan');
    return;
  }

  if (getCurrentPlan.value.type === props.plan?._key) return;
  if (
    getCurrentPlan.value.type === 'enterprise_plan' &&
    (props.plan?._key === 'starter' || props.plan?._key === 'pro')
  ) {
    showDowngradeModal.value = true;
    return;
  }
  if (getCurrentPlan.value.type === 'pro' && props.plan?._key === 'starter') {
    showDowngradeModal.value = true;
    return;
  }
  emit('onChoosePlan', props.plan);
};

const buttonColors = {
  starter: 'tint',
  pro: 'accent',
  enterprise: 'primary',
  dedicated: 'white',
  free: 'outline',
};

const showDowngradeModal = ref(false);

const isOnboarding = computed(() => route.fullPath === '/onboarding');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", {
      class: _normalizeClass(["pt-6 px-6 pb-8 flex flex-col gap-6 border gb-border-border-hover rounded-24 gb-bg-bg-base min-w-[300px] md:flex-grow sm:flex-grow xs:flex-grow", {
      '!border-2 !border-[#2772F5]': props.plan?._id === 'plans/pro',
      dedicated: props.plan?._id === 'plans/dedicated',
    }])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col gap-6", { '!gap-3': __props.plan._id === 'plans/enterprise_plan' }])
      }, [
        _createVNode(PricingCardHeader, {
          "plan-id": props.plan._id,
          name: props.plan.name,
          price: props.plan.periods_with_prices[props.period].first_payment.discount_price,
          "base-price": props.plan?.price,
          period: __props.period
        }, null, 8, ["plan-id", "name", "price", "base-price", "period"]),
        _createVNode(PricingCardParametrs, {
          "plan-id": props.plan._id,
          price: props.plan.periods_with_prices[props.period].first_payment.discount_price,
          "access-tokens": props.plan.max_token_amount,
          "cu-amount": props.plan.cu_amount,
          rps: props.plan.rps,
          "base-price": props.plan?.price
        }, null, 8, ["plan-id", "price", "access-tokens", "cu-amount", "rps", "base-price"])
      ], 2),
      _createVNode(_unref(GetBlockUIButton), {
        onClick: handleClickButtonChoosePlan,
        "color-type": buttonColors[props.plan?.name.toLocaleLowerCase()]
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            class: _normalizeClass(["font-bold leading-20 m-auto", { '!text-text-main': __props.plan._id === 'plans/dedicated' }])
          }, _toDisplayString(_unref(getCurrentPlan).name === __props.plan.name && __props.plan._id !== 'plans/dedicated'
            ? ( (isOnboarding.value && __props.plan._id === 'plans/free') ? _ctx.$t(`pricing-card.continue`) : _ctx.$t(`pricing-card.your-current-plan`))
            : _ctx.$t(`pricing-card.plans.${getPlanId.value}.button`)), 3)
        ]),
        _: 1
      }, 8, ["color-type"]),
      _createElementVNode("div", {
        class: _normalizeClass(["w-full h-0.5 gb-bg-border-hover", { 'bg-[#494E58]': __props.plan._id === 'plans/dedicated' }])
      }, null, 2),
      _createVNode(PricingCardOptions, {
        type: props.plan._key
      }, null, 8, ["type"])
    ], 2),
    _withDirectives(_createVNode(DowngradeModal, {
      onClose: _cache[0] || (_cache[0] = ($event: any) => (showDowngradeModal.value = false))
    }, null, 512), [
      [_vShow, showDowngradeModal.value]
    ])
  ], 64))
}
}

})