import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]




export default /*@__PURE__*/_defineComponent({
  __name: 'ModalTemplate',
  props: {
  id: {
    type: String,
    default: '',
  },
  rounded: {
    type: String,
    default: 'rounded-16',
  },
  paddings: {
    type: String,
    default: 'p-8 xs:px-4 xs:py-6',
  },
  additionalClasses: {
    type: String,
    default: '',
  },
  additionalStyles: {
    type: String,
    default: '',
  },
},
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const props = __props;

const closeModal = () => {
  emit('close');
};



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal fixed z-20 top-0 left-0 h-full w-full flex justify-center items-center xs:items-end xs:left-0 xs:right-0",
    id: props.id,
    onClick: _withModifiers(closeModal, ["self"])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal__content bg-bg-base dark:bg-bg-dark-base relative overflow-auto flex flex-col xs:w-full xs:rounded-b-none gap-8 xs:gap-6", `${props.rounded} ${props.paddings} ${props.additionalClasses}`]),
      style: _normalizeStyle([__props.additionalStyles])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 6)
  ], 8, _hoisted_1))
}
}

})