export const cn ={
  "notification": {
    "error": {
      "authentication": {
        "register": "\u6ce8\u518c\u8fc7\u7a0b\u4e2d\u53d1\u751f\u9519\u8bef",
        "login": "\u6ce8\u518c\u8fc7\u7a0b\u4e2d\u53d1\u751f\u9519\u8bef",
        "recover-password": "\u627e\u56de\u5bc6\u7801\u8fc7\u7a0b\u4e2d\u53d1\u751f\u9519\u8bef",
        "set-password": "\u5728\u5236\u5b9a\u65b0\u5bc6\u7801\u8fc7\u7a0b\u4e2d\u53d1\u751f\u9519\u8bef",
        "sign-in": "电子邮箱或者密码错误"
      },
      "webhooks": {
        "get-public-key": "\u5728\u83b7\u53d6\u516c\u94a5\u8fc7\u7a0b\u4e2d\u53d1\u751f\u9519\u8bef",
        "generate-public-key": "\u5728\u4ea7\u751f\u65b0\u516c\u94a5\u8fc7\u7a0b\u4e2d\u53d1\u751f\u9519\u8bef"
      },
      "general": {
        "get-pricing": "\u5728\u5b9a\u4ef7\u8fc7\u7a0b\u4e2d\u53d1\u751f\u9519\u8bef",
        "get-increase-pricing": "無法取得增加請求的數據",
        "get-list-of-nodes": "\u5728\u83b7\u53d6\u8282\u70b9\u5217\u8868\u8fc7\u7a0b\u4e2d\u53d1\u751f\u9519\u8bef",
        "create-order": "\u5728\u5f62\u6210\u8ba2\u5355\u8fc7\u7a0b\u4e2d\u53d1\u751f\u9519\u8bef",
        "get-statistics": "\u5728\u83b7\u5f97\u7edf\u8ba1\u4fe1\u606f\u8fc7\u7a0b\u4e2d\u53d1\u751f\u9519\u8bef",
        "send-message": "\u5728\u53d1\u9001\u4fe1\u606f\u8fc7\u7a0b\u4e2d\u53d1\u751f\u9519\u8bef"
      },
      "dedicated-order": {
        "calculate-price": "\u65e0\u6cd5\u8ba1\u7b97\u4ef7\u683c",
        "get-dedicated-nodes": "\u65e0\u6cd5\u83b7\u53d6\u60a8\u7684\u4e13\u7528\u8282\u70b9",
        "create-order": "\u65e0\u6cd5\u521b\u5efa\u8ba2\u5355"
      },
      "projects": {
        "get-list-of-projects": "\u65e0\u6cd5\u83b7\u53d6\u9879\u76ee\u5217\u8868",
        "hide-endpoint": "\u65e0\u6cd5\u9690\u85cf\u7aef\u70b9",
        "get-new-access-token": "交换端点失败"
      },
      "endpoints": {
        "get-endpoints": "獲取端點失敗",
        "create-endpoint": "創建端點失敗",
        "delete-endpoint": "刪除端點失敗",
        "roll-endpoint": "滾動端點失敗",
        "rename-endpoint": "重新命名端點失敗"
      },
      "user": {
        "get-payment-history": "\u65e0\u6cd5\u83b7\u53d6\u4ed8\u6b3e\u5386\u53f2\u8bb0\u5f55",
        "get-account-information": "\u65e0\u6cd5\u83b7\u53d6\u8d26\u6237\u4fe1\u606f",
        "get-requests-balance": "\u65e0\u6cd5\u83b7\u5f97\u8bf7\u6c42\u4f59\u989d",
        "change-account-information": "\u65e0\u6cd5\u66f4\u6539\u8d26\u6237\u4fe1\u606f",
        "get-checkout-history": "獲取結帳歷史記錄失敗",
        "get-referral-info": "無法獲取推薦信息",
        "accept-referral-program": "無法加入推薦計劃",
        "send-payout-code": "發送驗證碼失敗",
        "create-payout": "無法創建付款",
        "get-notifications": "Failed to get notifications",
        "change-notifications-trigger": "Failed to configure notification settings",
        "enable-notifications-email-destination": "Failed to enable email notifications",
        "enable-notifications-telegram-destination": "Failed to enable telegram notifications",
        "enable-notifications-webhook-destination": "Failed to enable webhook notifications",
        "disable-notifications-email-destination": "Failed to disable email notifications",
        "disable-notifications-telegram-destination": "Failed to disable telegram notifications",
        "disable-notifications-webhook-destination": "Failed to disable webhook notifications",
        "change-notifications-webhook-url": {
          "title": "Unable to change URL",
          "description": "URL should respond with status code 200."
        }
      },
      "payment": {
        "payment": {
          "title": "支付失败",
          "description": "稍后再试"
        },
        "forbidden-country": {
          "title": "在您所在的国家/地区，由于制裁，Paddle 无法使用"
        }
      },
      "teams": {
        "create-new-team": "创建团队失败",
        "get-team-list": "无法获取队伍列表",
        "get-current-team": "无法获取队伍信息",
        "get-invite-list": "无法获取邀请列表",
        "respond-invite": "无法对邀请执行操作",
        "rename-team": "无法重命名团队",
        "remove-member": "移除成员失败",
        "add-member": "发送邀请失败",
        "rename-member": "无法重命名成员",
        "set-access-level": "无法更改访问级别",
        "team-auth": "团队帐号授权错误",
        "info": "获取信息失败"
      },
      "error-description": "\u6211\u4eec\u7aef\u53d1\u751f\u4e86\u95ee\u9898\uff0c\u8bf7\u7a0d\u540e\u518d\u8bd5"
    },
    "success": {
      "authentication": {
        "register": {
          "title": "\u8bf7\u68c0\u67e5\u60a8\u7684\u6536\u4ef6\u7bb1\uff01",
          "description": "\u6211\u4eec\u5411\u60a8\u53d1\u9001\u6b22\u8fce\u90ae\u4ef6!"
        },
        "forgot-password": {
          "title": "\u8bf7\u68c0\u67e5\u60a8\u7684\u6536\u4ef6\u7bb1\uff01",
          "description": "\u5bc6\u7801\u91cd\u7f6e\u94fe\u63a5\u5df2\u53d1\u9001\u81f3"
        }
      },
      "general": {
        "send-feedback": {
          "title": "\u8c22\u8c22\uff01\u60a8\u7684\u8bf7\u6c42\u5df2\u6210\u529f\u53d1\u9001\u3002",
          "description": "\u8c22\u8c22\uff01\u60a8\u7684\u8bf7\u6c42\u5df2\u6210\u529f\u53d1\u9001\u3002"
        }
      },
      "dedicated-order": {
        "create-project": {
          "title": "\u9879\u76ee\u521b\u5efa\u6210\u529f",
          "description": "\u8be5\u9879\u76ee\u5c06\u51fa\u73b0\u5728\u9879\u76ee\u5217\u8868\u4e2d"
        }
      },
      "payment": {
        "completed": {
          "title": "付款成功完成",
          "description": "您已成功购买套餐"
        }
      },
      "project": {
        "hide-endpoint": {
          "title": "端点删除成功"
        },
        "copy-endpoint": {
          "title": "端点已复制"
        },
        "copy-token": {
          "title": "令牌已复制"
        }
      },
      "access-token": {
        rename: {
          title: '访问令牌的名称已成功更改'
        }
      }
    },
    "attention": {
      "authentication": {
        "metamask": {
          "title": "\u60a8\u6ca1\u6709\u5b89\u88c5\u7684Metamask\u3002",
          "description": `\u8bf7\u4e0b\u8f7d <a href='https://metamask.io/' target='_blank' class='text-text-action font-medium leading-6'>Metamask</a> \u6269\u5c55\u624d\u80fd\u7ee7\u7eed\u3002`
        }
      },
      "teams": {
        "no-access-uid": {
          "title": "您已被从团队中移除",
          "description": "请联系团队管理员了解更多详细信息"
        }
      }
    }
  }
}