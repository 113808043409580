export type TimeFormatType = 'day' | 'hour' | 'minute' | 'second' | 'milliseconds';

export function useTimestamp() {
  const numberOfMillisecondsFromFormat: {[key in TimeFormatType]: (quantity: number) => number} = {
    milliseconds: (quantity) => quantity,
    second: function(quantity) {
      return this.milliseconds(quantity) * 1000
    },
    minute: function(quantity) {
      return quantity * this.second(60)
    },
    hour: function(quantity) {
      return quantity * this.minute(60);
    },
    day: function(quantity) {
      return quantity * this.hour(24);
    }
  }
  const parseDateToTimestampUTC = (dateString: string): number => {
    if (dateString.includes('.')) dateString = dateString.split('.')[0]
    const [datePart, timePart  = "00:00:00"] = dateString.split(' ');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hour, minute, second] = timePart.split(':').map(Number);
    return Date.UTC(year, month - 1, day, hour, minute, second);
  }

  const formattedDateToStringFromDateObjectUTC = (date: Date) => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    const milliseconds = String(date.getUTCMilliseconds()).padStart(5, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  const getDaysHoursMinutesFromTimestamp = (timestamp: number) => {
    return {
      days: Math.floor(timestamp / numberOfMillisecondsFromFormat.day(1)),
      hours: Math.floor((timestamp % numberOfMillisecondsFromFormat.day(1)) / numberOfMillisecondsFromFormat.hour(1)),
      minutes: Math.floor((timestamp % numberOfMillisecondsFromFormat.hour(1)) / numberOfMillisecondsFromFormat.minute(1))
    }
  }

  const addTimeToTimestamp = (timestamp: number, quantity: number ,format: TimeFormatType) => {
    const millisecond = numberOfMillisecondsFromFormat[format](quantity)
    return timestamp + millisecond
  }

  const getCurrentDateTimestamp = () => new Date().getTime();

  const getDateUTC = (): string => {
    const currentDate = new Date();

    const year = currentDate.getUTCFullYear();
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getUTCDate()).padStart(2, '0');
    const hours = String(currentDate.getUTCHours()).padStart(2, '0');
    const minutes = String(currentDate.getUTCMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getUTCSeconds()).padStart(2, '0');
    const milliseconds = String(currentDate.getUTCMilliseconds()).padStart(5, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  const getTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  const getDateISOFromTimestamp = (timestamp: number) => {
    const date = new Date(timestamp)
    return date.getFullYear() + "-" +
      String(date.getMonth() + 1).padStart(2, "0") + "-" +
      String(date.getDate()).padStart(2, "0") + "T" +
      String(date.getHours()).padStart(2, "0") + ":" +
      String(date.getMinutes()).padStart(2, "0") + ":" +
      String(date.getSeconds()).padStart(2, "0") + "." +
      (date.getMilliseconds() + "77").slice(0, 5);
  }

  return {
    numberOfMillisecondsFromFormat,
    formattedDateToStringFromDateObjectUTC,
    getDateUTC,
    getDaysHoursMinutesFromTimestamp,
    getDateISOFromTimestamp,
    getTimeZone,
    parseDateToTimestampUTC,
    addTimeToTimestamp,
    getCurrentDateTimestamp
  }
}