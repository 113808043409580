export default {
  paymentProcess: {
    paymentProcessModal: {
      title: {
        'SelectPaymentMethod': "选择付款方式",
        'UpgradePlan': '升级您的计划',
        'TopUp': '充值您的余额'
      },
      info: {
        statistics: '升级您的计划以获取统计数据',
        'team-members': '升级您的计划以添加团队成员',
        'tokens-amount': '请升级您的计划以增加访问令牌的数量。',
        'top-up-available': '任何付款计划均可充值'
      },
      buttons: {
        'upgrade': '升级',
        'proceed-checkout': '进行结算',
        'top-up': '充值',
        'get-pro': '获取专业版'
      },
    },
    parts: {
      'SelectedPaymentMethod': {
        'subscription': '订阅',
        'one-time': '一次性付款'
      },
      'SelectedPlan': {
        'per-month': '/ 每月',
        'cu-month': 'CU/月',
        'rps': 'RPS',
        'access-tokens': '访问令牌'
      },
      'TotalPrice': {
        'total': '全部的:',
        'vat': "不含增值税。最终价格取决于您所在的国家/地区。"
      },
      'TopUp': {
        'or': '或者',
        'cu-amount': 'CU 金额',
        'for': '为了',
        'cu': 'CU',
        '1m-cu': '/ 1M CU'
      }
    }
  }
}