<script lang="ts" setup>
import { computed, defineProps, PropType } from 'vue';
import {
  CUPlansIdType,
  DedicatedPlanType,
  EnterprisePlanCUType,
  ProPlanCUType,
  StarterPlanCUType,
} from '@/shared/products/types';
import { formatNumberCompact } from '@/utils/changeNumberDisplay';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  cuAmount: {
    type: Number,
    default: 0
  },
  rps: {
    type: Number,
    default: 0
  },
  accessTokens: {
    type: Number,
    default: 0
  },
  price: {
    type: Number,
    default: 0
  },
  planId: {
    type: String as PropType<CUPlansIdType<StarterPlanCUType | ProPlanCUType | EnterprisePlanCUType | DedicatedPlanType>>,
    default: ''
  },
  basePrice: {
    type: Number,
    default: 0
  },
});

const { t } = useI18n();

const CUAmount = computed(() => props.planId === 'plans/enterprise_plan' ? t('pricing-card.parameters.custom') : formatNumberCompact(props.cuAmount));
const RPS = computed(() => props.planId === 'plans/enterprise_plan' ?  t('pricing-card.parameters.custom') : props.rps);
const accessTokens = computed(() => props.planId === 'plans/enterprise_plan' ?  t('pricing-card.parameters.custom') : props.accessTokens)

</script>

<template>
  <div v-if='planId !== "plans/dedicated"' class="flex flex-col gap-3">
    <div class="flex justify-between items-center">
      <p class="leading-5 gb-text-text-main">
        <span class="font-bold me-1">{{ CUAmount }}</span>
        <span class="font-medium ">{{ $t('pricing-card.parameters.cu-month') }}</span>
      </p>
      <p v-if="props.planId !== 'plans/enterprise_plan' && props.planId !== 'plans/dedicated' && props.planId !== 'plans/free'"
         class="font-medium text-sm gb-text-text-secondary"
      >
        <span>${{ (( basePrice / cuAmount ) * 1000000).toFixed(2) }}</span>
        <span>{{ $t('pricing-card.parameters.1m-cu') }}</span>
      </p>
    </div>
    <p class="leading-5 gb-text-text-main">
      <span class="font-bold me-1">{{ RPS }}</span>
      <span class="font-medium">{{ $t('pricing-card.parameters.rps') }}</span>
    </p>
    <p class="leading-5 gb-text-text-main">
      <span class="font-bold me-1">{{ accessTokens }}</span>
      <span class="font-medium ">{{ $t('pricing-card.parameters.access-token') }}</span>
    </p>
  </div>
  <div v-else class="flex flex-col gap-3 h-[64px]">
    <div class="flex justify-between items-center">
      <p class="leading-5 gb-text-text-main">
        <span class="font-bold me-1">{{ $t('pricing-card.parameters.unlimited') }}</span>
        <span class="font-medium ">CU</span>
      </p>
    </div>
    <p class="leading-5 gb-text-text-main">
      <span class="font-bold me-1">{{ $t('pricing-card.parameters.up-to') }}: 1000</span>
      <span class="font-medium">{{ $t('pricing-card.parameters.rps') }}</span>
    </p>
  </div>
</template>
